import React, { useEffect, useState } from 'react';
import { connectStore } from 'redux-box';
import { module as activity } from 'store/activity';
import { module as onboard } from "@st/onboard";
import { module as freadomFuture } from "@st/freadomFuture";
import store from 'store';
import action from '@st/activity/actions';
import { ThemeButton } from "static/styled/custom.styled";


import TaskListCard from './TaskListCard';
import { CreateTaskButton, TaskListContainer, PreLiveClass, LiveClassTitle } from './TaskList.styled';

const TaskList = (props) => {

    const [ preLiveClass , setPreLiveClass ] = useState([])
    const [ postLiveClass , setPostLiveClass ] = useState([])

    useEffect(()=>{
       let taskList = props.freadomFuture.freadom_future_task_list;
       setPreLiveClass([]);
       setPostLiveClass([]);
       if(taskList.length > 0){
           taskList.forEach((task)=>{
            if (task.position_type === "pre-liveclass")  setPreLiveClass(oldArray => [...oldArray,task])
            else setPostLiveClass(oldArray => [...oldArray,task])
           })
       } 
    },[props.freadomFuture.freadom_future_task_list])
    return (
        <div>
            <CreateTaskButton>
                <ThemeButton onClick={() => {
                    props.freadomFuture.setActiveFreadomFutureTask(null);
                    store.dispatch(action.setEditorType('task'))
                }}>
                    + Create TASK
                </ThemeButton>
            </CreateTaskButton>
            <TaskListContainer>
                <PreLiveClass>
                    <LiveClassTitle>
                        Class Work
                    </LiveClassTitle>
                    {   
                        preLiveClass.length > 0 &&
                        preLiveClass.map((task) => (<TaskListCard taskData = {task} key = {task.id}/>))
                    }
                </PreLiveClass>
                <PreLiveClass>
                    <LiveClassTitle>
                        Home Work
                    </LiveClassTitle>
                    {   
                        postLiveClass.length > 0 &&
                        postLiveClass.map((task,i) => (<TaskListCard taskData = {task} key = {task.id}/>))
                    }
                </PreLiveClass>
            </TaskListContainer>
        </div>
    )
}
export default connectStore({
    activity, onboard,freadomFuture
})(TaskList)