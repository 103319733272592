import React, { Component } from "react";
import { uploadFile } from "@s/api/file";
import {
  ActivityCreatorNavContainer,
  ActivityDropdownLabel,
  ActivityDropdownLabelContainer,
  ActivityGradeDropdown,
  ActivityGradeDropdownContainer,
  ActivityGradeDropdownMenu,
  ActivityGradeSelectOption,
  ActivityDescription,
  ActivityDescriptionInfoContainer,
  ActivityDescriptionTextArea,
  FreadomPointsInputField,
} from "@sc/ActivityModal.styled";
import { ActivitySectionAdder } from "@c/activity-view/activity-creator/mcq-editor/McqEditor.styled";
import {
  SelectFileIn,
  ChangeImageButton,
  ActivityInputField,
  ActivityUploadImageButton,
  ImageContainer,
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import { ActivityImageContainer, EditIcon } from "./ActivityInfo.styled";
import {
  ThemeButton,
  ImageLoader,
  ModalCountItem,
  ModalCountOverlayContainer,
  DropDown,
  ToggleSlider,
  DropDownMenu,
  CheckBall,
  CheckBallInner,
  DropdownItem,
  FlexStart,
  SubLevelItem,
  Chevron,
} from "@sc/custom.styled";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import swal from "sweetalert2";
import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";
// export default connectStore({
//   activity
// })
// export default
class ActivityInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityLevelSelector: false,
      activityEdit: true,
      enableLoad: false,
      enableBackgroundImageLoad: false,
      backgroundImageChange: false,
      completed_count: 0,
      reading_count: 0,
      gradeSelected: "",
      displayMenu: false,
      activity_info: {
        id: "",
        grade: "",
        //grade: [],
        freadom_point: "",
        name: "",
        description: "",
        image: "",
        background_image: "",
      },
      gradeSelector: false,
      gradeList: [],
      selectedGrades: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activity && nextProps.activity.active_activity) {
      this.normalizeForState(nextProps.activity.active_activity);
    } else {
      this.setState({
        gradeSelected: "Select Grade",
      });
    }
  }

  normalizeForState = (activeActivity) => {
    let gradename = "";
    let gradeName = this.props.activity.grades.filter((grade) => {
      return grade.id === activeActivity.grade;
    });
    if (gradeName.length > 0) gradename = gradeName[0].name;
    this.setState({
      gradeSelected: gradename,
      activity_info: {
        ...this.state.activity_info,
        grade: activeActivity.grade,
      },
    });
  };

  componentDidMount() {
    let data = this.props.active_activity;
    let levelList = this.props.activity.grades.map((grade) => {
      let gradeData = {
        ...grade,
        selected: false,
        clicked: false,
      };
      return gradeData;
    });
    //FREAD-1182
    this.setState({
      gradeList: [
        //{
        //name: "Select All",
        //  selected: false,
        //  clicked: false
        // },
        ...levelList,
      ],
    });
    this.setState({
      imageChange: false,
      completed_count: data.completed_count,
      reading_count: data.reading_count,
      activity_info: {
        ...this.state.activity_info,
        id: data.id,
        grade: data.grade,
        freadom_point: data.freadom_point,
        name: data.name,
        description: data.description,
        image: data.image || null,
        background_image: data.background_image || "",
      },
    });
  }

  handleImageFocus = (actionType) => {
    if (actionType === "over" && this.state.imageChange === false)
      this.setState({
        imageChange: true,
      });
    else if (actionType === "out" && this.state.imageChange === true)
      this.setState({
        imageChange: false,
      });
  };

  _handleImageChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2MB ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          enableLoad: true,
          imageChange: false,
          activity_info: {
            ...this.state.activity_info,
            image: reader.result,
          },
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then((res) => {
        this.setState({
          activity_info: {
            ...this.state.activity_info,
            image: res.file,
          },
          enableLoad: false,
        });
      });
    }
  };

  handleBackgroundImageFocus = (actionType) => {
    if (actionType === "over" && this.state.backgroundImageChange === false)
      this.setState({
        backgroundImageChange: true,
      });
    else if (actionType === "out" && this.state.backgroundImageChange === true)
      this.setState({
        backgroundImageChange: false,
      });
  };

  handleBackgroundImageChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 2) {
      swal("Oops!", "File size greater than 2MB ", "warning");
    } else {
      reader.onloadend = () => {
        this.setState({
          enableBackgroundImageLoad: true,
          backgroundImageChange: false,
          activity_info: {
            ...this.state.activity_info,
            background_image: reader.result,
          },
        });
      };
      reader.readAsDataURL(file);
      uploadFile(file).then((res) => {
        this.setState({
          activity_info: {
            ...this.state.activity_info,
            background_image: res.file,
          },
          enableBackgroundImageLoad: false,
        });
      });
    }
  };

  handleActivityNameChange = (e) => {
    this.setState({
      activity_info: { ...this.state.activity_info, name: e.target.value },
    });
  };
  handleDescriptionValue = (e) => {
    this.setState({
      activity_info: {
        ...this.state.activity_info,
        description: e.target.value,
      },
    });
  };

  handleActivityEditClick = (e) => {
    this.setState({
      activityEdit: false,
    });
  };

  handleDoneBtn = (e) => {
    this.setState({
      activityEdit: true,
    });
    document.getElementById("activityName").removeAttribute("disabled");
    this.props.activity.updateActivity(this.state.activity_info);
    //this.props.activity.updateActivity({...this.state.activity_info,grade:this.state.selectedGrades});
  };

  displayGradeMenu = (e) => {
    this.setState({
      displayMenu: !this.state.displayMenu,
    });
  };

  handleGradeSelected = (newgrade) => (e) => {
    this.setState({
      displayMenu: !this.state.displayMenu,
      gradeSelected: newgrade.name,
      activity_info: { ...this.state.activity_info, grade: newgrade.id },
    });
  };

  handleFreadomPoints = (evt) => {
    if (
      evt.target.value.length === 0 &&
      this.state.activity_info.freadom_point !== ""
    ) {
      this.setState({
        activity_info: {
          ...this.state.activity_info,
          freadom_point: "",
        },
      });
    } else
      this.setState({
        activity_info: {
          ...this.state.activity_info,
          freadom_point: evt.target.value,
        },
      });
  };

  handleGradeSelector = (val) => {
    let newGradeData = this.state.gradeList.map((grade) => {
      return { ...grade, clicked: false };
    });
    this.setState({
      gradeList: newGradeData,
      gradeSelector: val,
    });
  };

  handleGradeChange = (val) => {
    this.handleGradeSelector(true);
    console.log("val", val);
    //FREAD-1182
    let // newGrades = [];
      // if(val === 'Select All' || val === 'Unselect All')
      // newGrades= this.state.gradeList.map((grade,i) => {
      //   if (grade.name === val){
      //     return { ...grade, selected: val === 'Select All',name:val === 'Select All'?'Unselect All':'Select All' };
      //   }else{
      //     return { ...grade, selected: val === 'Select All'}
      //   }

      // })
      // else
      newGrades = this.state.gradeList.map((grade, i) => {
        if (grade.name === val) {
          return { ...grade, selected: !grade.selected };
        } else {
          return { ...grade };
        }
      });
    const result = newGrades.filter((gr) => gr.selected == true);
    console.log(newGrades);
    this.setState({
      gradeList: newGrades,
      selectedGrades: result,
      //selectedGrades: result.filter((res)=>res.id!=null).map((res)=>res.id)
    });
  };

  render() {
    const { state } = this;
    const { activity } = this.props;
    const { permission } = this.props.onboard;
    const { user } = this.props.onboard;

    const gradeOptions = activity.grades.map((grade, i) => (
      <ActivityGradeSelectOption
        onClick={this.handleGradeSelected(grade)}
        key={i}
      >
        {grade.name}
      </ActivityGradeSelectOption>
    ));

    return (
      <ActivityDescriptionInfoContainer style={{ position: "relative" }}>
        {this.state.enableLoad ? (
          <ActivityImageContainer>
            <ImageLoader>
              <img
                alt="loading"
                width="40"
                height="40"
                src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
              />
            </ImageLoader>
          </ActivityImageContainer>
        ) : (
          <ActivityImageContainer
            onMouseOver={() => this.handleImageFocus("over")}
            onMouseOut={() => this.handleImageFocus("out")}
          >
            <ImageContainer
              src={
                this.state.activity_info.image
                  ? this.state.activity_info.image
                  : require("static/img/default-image.png")
              }
            />

            {!this.state.activityEdit ? (
              <ChangeImageButton
                top={this.state.imageChange ? "83%" : "100%"}
                opacity={this.state.imageChange ? 1 : 0}
              >
                Change Image
              </ChangeImageButton>
            ) : null}
            {!this.state.activityEdit ? (
              <SelectFileIn
                type="file"
                onChange={(e) => this._handleImageChange(e)}
              />
            ) : null}
          </ActivityImageContainer>
        )}
        {this.state.activity_info.background_image === "" ? (
          <ActivityImageContainer width="200px" marginLeft="14px">
            <ActivityUploadImageButton type="button">
              <img
                src={require("static/img/cloud-upload-icon.png")}
                alt="Upload"
              />
              <span>Upload Fullscreen Image</span>
            </ActivityUploadImageButton>

            {!this.state.activityEdit ? (
              <SelectFileIn
                width="200px"
                type="file"
                onChange={(e) => this.handleBackgroundImageChange(e)}
              />
            ) : null}
          </ActivityImageContainer>
        ) : this.state.enableBackgroundImageLoad ? (
          <ActivityImageContainer width="200px" marginLeft="14px">
            <ImageLoader>
              <img
                alt="loading"
                width="30"
                height="30"
                src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
              />
            </ImageLoader>
          </ActivityImageContainer>
        ) : (
          <ActivityImageContainer
            width="200px"
            marginLeft="14px"
            onMouseOver={() => this.handleBackgroundImageFocus("over")}
            onMouseOut={() => this.handleBackgroundImageFocus("out")}
          >
            <ImageContainer src={this.state.activity_info.background_image} />
            {!this.state.activityEdit ? (
              <ChangeImageButton
                top={this.state.backgroundImageChange ? "83%" : "100%"}
                opacity={this.state.backgroundImageChange ? 1 : 0}
              >
                Change Background
              </ChangeImageButton>
            ) : null}
            {!this.state.activityEdit ? (
              <SelectFileIn
                type="file"
                onChange={(e) => this.handleBackgroundImageChange(e)}
              />
            ) : null}
          </ActivityImageContainer>
        )}
        <ActivityDescription>
          <ActivitySectionAdder>
            {state.activityEdit ? (
              <div>
                {this.props.activity.active_activity.activity_pack === null
                  ? "Grade : " + this.state.gradeSelected + " | "
                  : null}
                Freadom Points:
                {state.activity_info.freadom_point}
              </div>
            ) : (
              <ActivityCreatorNavContainer>
                {this.props.activity.active_activity &&
                this.props.activity.active_activity.activity_pack === null ? (
                  <ActivityGradeDropdownContainer>
                    <ActivityGradeDropdown onClick={this.displayGradeMenu}>
                      <ActivityDropdownLabelContainer>
                        <ActivityDropdownLabel>
                          {this.state.gradeSelected}
                        </ActivityDropdownLabel>
                        <img
                          src={require("static/img/downarrowicon.png")}
                          alt="down-arrow"
                        />
                      </ActivityDropdownLabelContainer>
                    </ActivityGradeDropdown>
                    {this.state.displayMenu ? (
                      <ActivityGradeDropdownMenu>
                        {gradeOptions}
                      </ActivityGradeDropdownMenu>
                    ) : null}
                  </ActivityGradeDropdownContainer>
                ) : // <DropDown
                //   minWidth="160px"
                //   active={this.state.gradeSelector}
                //   onBlur={() => this.handleGradeSelector(false)}
                //   tabIndex="1"
                // >
                //   <div
                //     style={{ fontSize: "14px", height: "24px" }}
                //     onClick={() =>
                //       this.handleGradeSelector(!this.state.gradeSelector)
                //     }
                //   >
                //   {this.state.selectedGrades.length >= 12
                //     ? "All "
                //     : this.state.selectedGrades.length + " "}{" "}
                //     Grade Selected
                //   <Chevron>
                //     <img
                //       src={require("static/img/downarrowicon.png")}
                //       alt="down-arrow"
                //     />
                //   </Chevron>
                // </div>
                // <DropDownMenu
                //   maxHeight="150px"
                //   active={this.state.gradeSelector}
                //   color="#fff"
                //   style={{ padding: "0px" }}
                // >
                //   {this.state.gradeList.map((grade, index) => (
                //     <DropdownItem>
                //       <FlexStart
                //         style={{
                //           width: "100%",
                //           padding: "6px 8px",
                //           position: "relative",
                //           borderBottom: "solid 1px #e6e6e6",
                //         }}
                //         onClick={() => this.handleGradeChange(grade.name)}
                //       >
                //         <CheckBall
                //           style={{ marginRight: "6px" }}
                //           active={grade.selected}
                //         >
                //           <CheckBallInner
                //             active={grade.selected }
                //           />
                //         </CheckBall>

                // {grade.selected}{grade.name}
                //</FlexStart>

                // </DropdownItem>
                // ))}
                //</DropDownMenu>
                //</DropDown>
                // </ActivityGradeDropdownContainer>
                null}
                <FreadomPointsInputField
                  primary
                  width={"10px"}
                  style={
                    this.props.activity.active_activity.activity_pack !== null
                      ? { marginLeft: "0px" }
                      : {}
                  }
                >
                  <input
                    type="number"
                    placeholder="Points"
                    value={this.state.activity_info.freadom_point}
                    onChange={this.handleFreadomPoints}
                  />
                </FreadomPointsInputField>
              </ActivityCreatorNavContainer>
            )}

            {user.read_only == false && Permission.getPermission(permission, "change_activity") ? (
              state.activityEdit ? (
                <span>
                  <EditIcon
                    src={require("static/img/editicon.png")}
                    alt="edit icon"
                    className="image-fluid"
                    onClick={this.handleActivityEditClick}
                  />
                </span>
              ) : (
                <ThemeButton primary onClick={this.handleDoneBtn}>
                  Done
                </ThemeButton>
              )
            ) : null}
          </ActivitySectionAdder>
          <ActivityInputField
            primary
            placeholder="New Activity Here"
            border={
              state.activityEdit ? "none" : "solid 1px rgba(40,50,112,0.1)"
            }
            id="activityName"
            value={state.activity_info.name}
            left={!state.activityEdit ? "6px" : "0px"}
            width={!state.activityEdit ? "75%" : "100%"}
            onChange={this.handleActivityNameChange}
            disabled={this.state.activityEdit}
          />

          <ActivityDescriptionTextArea
            primary={!state.activityEdit}
            id="activityDescription"
            disabled={state.activityEdit}
            onChange={this.handleDescriptionValue}
            value={state.activity_info.description}
          />
        </ActivityDescription>

        {this.state.activityEdit && (
          <ModalCountOverlayContainer width="12%" style={{ right: "10px" }}>
            <ModalCountItem>
              <span> {state.reading_count}</span>
              <i className="fa fa-sticky-note-o" />
            </ModalCountItem>
            <ModalCountItem>
              <span> {state.completed_count} </span>
              <i className="fa fa-bullseye" />
            </ModalCountItem>
          </ModalCountOverlayContainer>
        )}
      </ActivityDescriptionInfoContainer>
    );
  }
}
export default connectStore({
  activity,
  onboard,
})(ActivityInfo);
