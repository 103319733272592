import { createSagas } from "redux-box";
import StoryApi from "@s/api/story";
import { call, put } from "redux-saga/effects";
import swal from "sweetalert2";

const errorHandler = err => {
  swal("Oops!", err.error_message, "error");
};

const defaultSections = ["Resources", "What will you learn", "Story Steps"];

export default createSagas({
  REQUEST_COLLECTIONS: function* () {
    try {
      yield put({
        type: "SET_FETCH_COLLECTIONS",
        val: true
      });
      const res = yield call(StoryApi.getAllCollections);
      yield put({
        type: "SET_COLLECTION_LIST",
        collectionList: res
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_COLLECTIONS",
        val: false
      });
    }
  },

  REQUEST_CREATE_COLLECTION: function* ({ collection }) {
    try {
      yield call(StoryApi.createCollection, collection);

      yield put({
        type: "REQUEST_COLLECTIONS"
      });
      swal("Done", "Collection created successfully", "success");
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_UPDATE_COLLECTION: function* ({ collection }) {
    try {
      const gState = require("store").default.getState();
      const activeCollectionId = gState.story.active_collection.id;
      yield call(StoryApi.updateCollection, activeCollectionId, collection);
      yield put({
        type: "SET_ACTIVE_COLLECTION",
        collection: null
      });
      yield put({
        type: "REQUEST_COLLECTIONS"
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  //................................Story Crud Sagas............
  REQUEST_STORIES: function* () {
    try {
      yield put({
        type: "SET_FETCH_STORY_LIST",
        value: true
      });
      const gState = require("store").default.getState();
      const search = gState.story.search_story;
      // let filterLevel=globalState.story.filterLevelSearch;
      const gradeLevel =
        gState.story.active_story_grade_level +
        ":" +
        gState.story.active_story_level;
      const globalState = require("store").default.getState();
      const filterGradeSort = gState.story.filterGradeSort;
      const filter = gState.story.activeFilter;
      let collection = gState.story.selected_collections;
      let sources = gState.story.selected_sources;
      let date = globalState.story.story_date;
      let storyType = globalState.story.story_type;

      var res = yield call(
        StoryApi.getAllStories,
        gradeLevel,
        0,
        search,
        collection,
        filterGradeSort,
        filter,
        date,
        storyType,
      );
      yield put({
        type: "SET_STORIES_RESULT",
        storiesResult: res
      });
      if (res && res.result) {
        if(sources.length>0) {
          res.result= res.result.filter(s=> sources.indexOf(s.source)>-1);
        } 
        yield put({
          type: "SET_STORY_LIST",
          storyList: res.result
        });
      }
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_STORY_LIST",
        value: false
      });
    }
  },
  REQUEST_STORIES_LIST_CONCAT: function* ({ offset }) {
    try {
      const gState = require("store").default.getState();
      const search = gState.story.search_story;
      const gradeLevel =
        gState.story.active_story_grade_level +
        ":" +
        gState.story.active_story_level;
      const filterGradeSort = gState.story.filterGradeSort;
      const filter = gState.story.activeFilter;
      const collection = gState.story.selected_collections;
      let sources = gState.story.selected_sources;
      const globalState = require("store").default.getState();
      let date = globalState.story.story_date;
      let storyType = globalState.story.story_type;
      let res = yield call(
        StoryApi.getAllStories,
        gradeLevel,
        offset,
        search,
        collection,
        filterGradeSort,
        filter,
        date,
        storyType
      );
      yield put({
        type: "SET_STORIES_RESULT",
        storiesResult: res
      });
      if (res && res.result && res.result.length > 0) {
        if(sources.length>0) {
          res.result= res.result.filter(s=> sources.indexOf(s.source)>-1);
        }
        yield put({
          type: "SET_CONCAT_STORY_LIST",
          storyList: res.result
          // activities: res.sort(function(a, b){return a.order - b.order})
        }); 
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_UPDATE_STORY: function* ({ story }) {
    try {
      const gState = require("store").default.getState();
      const collections = gState.story.story_collections;
      let storyId = story.id;
      if (storyId === undefined) {
        storyId = gState.story.active_story.id;
      }
      const res = yield call(StoryApi.updateStory, storyId, {
        ...story,
        collection: collections
      });
      yield put({
        type: "REQUEST_STORY_BY_ID",
        storyId: res.id
      });

      // yield put({
      //   type: "REQUEST_STORIES"
      // });
      let data = res;
      data.storyIndex = story.storyIndex;
      yield put({
        type: "UPDATE_STORY_LIST",
        payload: data
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_CREATE_STORY: function* ({ story }) {
    try {
      const res = yield call(StoryApi.createStory, story);
      yield put({
        type: "REQUEST_STORIES"
      });

      if (
        res &&
        res.section_data &&
        res.section_data.length === 0 &&
        res.story_type === "sectioned"
      ) {
        defaultSections.forEach(section => {
          let sectionData = {
            name: section,
            story: res.id,
            content_type: "text",
            media: []
          };
          require("store").default.dispatch({
            type: "REQUEST_CREATE_STORY_SECTIONED_TYPE",
            section: sectionData
          });
        });
      }
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_CREATE_STORY_SECTIONED_TYPE: function* ({ section }) {
    try {
      yield call(StoryApi.createStorySection, section);
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_CREATE_STORY_SECTION: function* ({ section }) {
    try {
      const gState = require("store").default.getState();
      const active_section = gState.story.active_section;
      const story_id = gState.story.active_story.id;
      const fileType = gState.story.current_story_type;
      yield call(StoryApi.createStorySection, {
        content_type: fileType,
        description: "",
        media: [],
        ...section,
        ...active_section,
        story: story_id
      });
      // flush the active_section from new_sections since it's been created
      yield put({
        type: "REMOVE_NEW_STORY_SECTION",
        section: gState.readingChallenge.active_section
      });
      yield put({
        type: "REQUEST_STORY_BY_ID",
        storyId: story_id
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_UPDATE_STORY_SECTION: function* ({ section }) {
    try {
      const globalState = require("store").default.getState();
      const story_id = globalState.story.active_story.id;
      const active_section = globalState.story.active_section;

      yield call(StoryApi.updateStorySection, active_section.id, ...section);

      yield put({
        type: "REQUEST_STORY_BY_ID",
        storyId: story_id
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  DELETE_STORY_SECTION: function* ({ section }) {
    try {
      const globalState = require("store").default.getState();
      const story_id = globalState.story.active_story.id;
      yield put({
        type: "UNSET_ACTIVE_STORY_SECTION"
      });
      yield call(StoryApi.deleteStorySection, section.id);
      yield put({
        type: "REQUEST_STORY_BY_ID",
        storyId: story_id
      });
    } catch (err) {
      console.log(err);
      errorHandler(err);
    }
  },

  REQUEST_STORY_BY_ID: function* ({ storyId }) {
    try {
      yield put({
        type: "SET_FETCH_ACTIVE_STORY",
        value: true
      });
      const res = yield call(StoryApi.getStoryById, storyId);
      let fileType = "";
      let section = null;
      if (res && res.section_data && res.section_data.length > 0) {
        fileType = res.section_data[0].content_type;
        yield put({
          type: "SET_STORY_TYPE",
          storyType: fileType
        });
      }

      let collectionList = [];
      if (res.collection.length > 0)
        collectionList = res.collection.map(collection => {
          return collection.id;
        });
      yield put({
        type: "SET_STORY_COLLECTION_LIST",
        storyCollections: collectionList
      });

      yield put({
        type: "SET_STORY_MAIN_TYPE",
        storyMainType: res.story_type
      });
      if (res.story_type !== "Sectioned") {
        if (res.section_data.length === 1) section = res.section_data[0];
        yield put({
          type: "SET_ACTIVE_STORY_SECTION",
          section: section
        });
      }

      yield put({
        type: "SET_ACTIVE_STORY",
        story: res
      });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "FETCH_LOAD_SET_TOP_STORY",
        val: false
      });
      yield put({
        type: "SET_FETCH_ACTIVE_STORY",
        value: false
      });
    }
  },

  //.....................Active Story collection addition and deletion.........
  REQUEST_ADD_STORY_COLLECTION: function* ({ collectionId }) {
    try {
      const globalState = require("store").default.getState();
      let collection = globalState.story.active_story.collection.map(data => {
        return data.id;
      });
      let storyId = globalState.story.active_story.id;
      if (collection.length > 0) {
        if (
          !collection.some(
            data => data.toLowerCase() === collectionId.toLowerCase()
          )
        ) {
          let data = { collection: [...collection, collectionId] };
          yield call(StoryApi.updateStoryCollection, storyId, data);
          yield put({
            type: "REQUEST_STORY_BY_ID",
            storyId: storyId
          });
        }
      } else {
        let data = { collection: [collectionId] };
        yield call(StoryApi.updateStoryCollection, storyId, data);
        yield put({
          type: "REQUEST_STORY_BY_ID",
          storyId: storyId
        });
      }

      yield put({
        type: "REQUEST_STORIES",
        storyId: storyId
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_REMOVE_STORY_COLLECTION: function* ({ collectionId }) {
    try {
      const globalState = require("store").default.getState();
      let storyId = globalState.story.active_story.id;
      let collection = globalState.story.active_story.collection.filter(
        data => data.id !== collectionId
      );
      collection = collection.map(data => {
        return data.id;
      });
      const data = { collection: [...collection] };
      yield call(StoryApi.updateStoryCollection, storyId, data);
      yield put({
        type: "REQUEST_COLLECTIONS"
      });
      yield put({
        type: "REQUEST_STORY_BY_ID",
        storyId: storyId
      });
      yield put({
        type: "REQUEST_STORIES",
        storyId: storyId
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  //...........................Story Question CRUD................
  REQUEST_CREATE_STORY_QUESTION: function* ({ question }) {
    try {
      yield call(StoryApi.createStoryQuestion, question);
      swal("Done!", "Created question!", "success");
      yield put({
        type: "REQUEST_STORY_QUESTIONS_BY_ID",
        storyId: question.story
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_STORY_QUESTIONS_BY_ID: function* ({ storyId }) {
    try {
      const res = yield call(StoryApi.getStoryQuestionsById, storyId);
      yield put({
        type: "SET_ALL_STORY_QUESTION",
        question: res
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_UPDATE_STORY_QUESTION: function* ({ question }) {
    try {
      const gState = require("store").default.getState();
      const questionId = gState.story.active_mcq.id;

      for (let i = 0; i < question.options.length; i++) {
        let option = question.options[i];
        yield StoryApi.updateStoryQuestionOption(option.id, option);
      }
      
      delete question.options;
      yield call(StoryApi.updateStoryQuestion, questionId, question);
      
      swal("Done!", "Updated question!", "success");
      yield put({
        type: "REQUEST_STORY_QUESTIONS_BY_ID",
        storyId: question.story
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_DELETE_STORY_QUESTION: function* ({ question }) {
    try {
      yield call(StoryApi.deleteStoryQuestion, question.id);
      swal("Done!", "The selected question was deleted!", "success");
      yield put({
        type: "REQUEST_STORY_QUESTIONS_BY_ID",
        storyId: question.story
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_SET_STORY_TYPE: function* ({ storyType }) {
    const globalState = require("store").default.getState();
    let storyId = globalState.story.active_story.id;
    yield put({
      type: "SET_STORY_MAIN_TYPE",
      storyMainType: storyType
    });
    yield put({
      type: "REQUEST_STORY_BY_ID",
      storyId: storyId
    });
  },
  REQUEST_SET_STORY_FILE_TYPE: function* ({ fileType }) {
    //const globalState = require("store").default.getState();
    //let storyId = globalState.story.active_story.id;

    yield put({
      type: "SET_STORY_TYPE",
      storyType: fileType
    });
    // yield put({
    //     type: "REQUEST_STORY_BY_ID",
    //     storyId: storyId
    // });
  },

  REQUEST_PUBLISH_STORY_EXPERIENCE: function* ({ experience }) {
    try {
      let data = { is_published: !experience.is_published };
      const res = yield call(
        StoryApi.updateStoryExperience,
        experience.id,
        data
      );
      if (experience.show)
        yield put({
          type: "SET_ACTIVE_STORY_EXPERIENCE",
          experience: res
        });
      const globalState = require("store").default.getState();
      const story_id = globalState.story.active_story.id;
      yield put({
        type: "REQUEST_STORY_BY_ID",
        storyId: story_id
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_DELETE_STORY_EXPERIENCE: function* ({ expId }) {
    try {
      yield call(StoryApi.deleteStoryExperience, expId);
      const globalState = require("store").default.getState();
      const story_id = globalState.story.active_story.id;
      yield put({
        type: "REQUEST_STORY_BY_ID",
        storyId: story_id
      });
      yield put({
        type: "SET_ACTIVE_STORY_EXPERIENCE",
        experience: null
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_DELETE_STORY: function* ({ story }) {
    try {
      yield call(StoryApi.deleteStory, story.storyId);
      // yield put({
      //   type: "REQUEST_STORIES"
      // });
      yield put({
        type: "DELETE_STORY",
        storyIndex: story.storyIndex
      });
    } catch (err) {
      errorHandler(err);
    }
  }
});
