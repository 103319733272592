import React, { Component } from "react";
import {
  CardContainer,
  FooterText,
  StyledDiv,
  StyledDivImage,
  Title,
  OrderInputField,
} from "./ActivityCard.styled";

import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import {
  CardIcon,
  CardIconContainer,
  CountOverlayContainer,
  CountItem,
} from "static/styled/custom.styled";
import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";
// export default connectStore({
//   activity,
// })
class ActivityCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: "",
      enableReorder: false,
      displayMenu: false,
      new_order: "",
    };
  }

  componentDidMount() {
    this.setState({
      optionSelected: this.props.activityId,
      displayMenu: false,
    });
  }

  componentWillReceiveProps(nextProps) {
    // debugger;
    if (
      nextProps.activity &&
      nextProps.activity.order_no === this.props.activityInfo.order
    ) {
      this.setState({
        new_order: nextProps.activity.order_no,
      });
    } else {
      this.setState({
        new_order: this.props.activityInfo.order,
      });
    }
  }

  displayGradeMenu = (e) => {
    this.setState({
      displayMenu: !this.state.displayMenu,
    });
  };

  handleOptionSelected = (newoption) => (e) => {
    this.setState({
      displayMenu: !this.state.displayMenu,
      optionSelected: newoption,
    });
  };

  handleOrderChange = (evt) => {
    evt.preventDefault();
    this.setState({
      new_order: evt.target.value,
    });
  };

  changeOrder = (evt) => {
    if (evt.target.value !== "" && evt.key === "Enter") {
      this.props.activity.reorderActivity({
        id: this.props.activityInfo.id,
        new_order: this.state.new_order,
      });
      this.props.activity.enableReorder(false);
    }
  };

  handleDefaultClick = (evt) => {
    evt.preventDefault();
    this.setState({
      enableReorder: true,
    });
  };

  render() {
    const { activityInfo } = this.props;
    const { user } =this.props.onboard;
    return (
      <CardContainer>
        <StyledDiv secondary>
          <Title>{activityInfo.name}</Title>

          <Title primary>
            {this.props.activityInfo.description.length < 36
              ? activityInfo.description
              : activityInfo.description.slice(0, 40) + "..."}
          </Title>
          <FooterText>
            {this.props.activityInfo.tag !== null &&
              activityInfo.tag.slice(0, 2).map((tag, i) => (
                <span key={i}>
                  {" "}
                  {tag}
                  {i === 0 && activityInfo.tag.length > 1 && <i> .</i>}
                </span>
              ))}
          </FooterText>
        </StyledDiv>
        <StyledDivImage primary image={activityInfo.image}>
          <CardIconContainer>
          {user.read_only == false?
            <CardIcon
              primary
              onClick={this.props.handlePublishClick}
              bg={activityInfo.is_published}
            >
              <i className="fa fa-check" />
            </CardIcon>
            :null}
            {user.read_only == false? 
            <CardIcon onClick={this.props.handleDeleteClick}>
              <i className="fa fa-trash" />
            </CardIcon>:null}
          </CardIconContainer>
          {this.props.activity.enableOrder ? (
            <OrderInputField
              type="number"
              disabled={true}
              value={this.props.activityInfo.order}
            />
          ) : null}
          <CountOverlayContainer style={{ width: "40px" }} primary>
            <CountItem>
              <i className="fa fa-sticky-note-o" />
              <span> {activityInfo.reading_count} </span>
            </CountItem>
            <CountItem>
              <i className="fa fa-bullseye" />
              <span>{activityInfo.completed_count} </span>
            </CountItem>
          </CountOverlayContainer>
        </StyledDivImage>
      </CardContainer>
    );
  }
}

// export default ActivityCard;

export default connectStore({
  activity,onboard
 })(ActivityCard)
