import {
  ThemeButton,
  McqSectionHeader,
  McqItemContainer,
  McqDescription,
  McqToolbar,
} from "static/styled/custom.styled";
import { McqSectionContainer } from "./QuizMcqSection.styled";
import store from "store";
import action from "@st/activity/actions";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as quiz2 } from "@st/quiz2";
import swal from "sweetalert2";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
import React, { Component } from "react";

class QuizMcqSection extends Component {
  handleEditClick = (data) => {
    let { permission } = this.props.onboard;
    if (Permission.getPermission(permission, "change_quiz")) {
      this.props.quiz2.setActiveQuizMcq(data);
      store.dispatch(action.setEditorType("mcq"));
    } else {
      swal({
        text: "You do not have permission.",
        type: "warning",
      });
    }
  };

  handleDeleteClick = (question, evt) => {
    let { permission } = this.props.onboard;
    if (Permission.getPermission(permission, "change_quiz")) {
      this.props.activity.closeEditor();
      swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.props.quiz2.requestDeleteQuizQuestion(question);
        }
      });
      evt.stopPropagation();
    } else {
      swal({
        text: "You do not have permission.",
        type: "warning",
      });
    }
  };

  render() {
    let { quiz2 } = this.props;
    let { permission } = this.props.onboard;
    let { user } = this.props.onboard;

    return (
      <McqSectionContainer>
        <McqSectionHeader>
          Assign QuizQuestion
          {Permission.getPermission(permission, "change_quiz") ? (
            <ThemeButton
              onClick={() => {
                this.props.quiz2.setActiveQuizMcq(null);
                store.dispatch(action.setEditorType("mcq"));
              }}
            >
              + Create Question
            </ThemeButton>
          ) : null}
        </McqSectionHeader>
        {quiz2.questions &&
          quiz2.questions.map((data, idx) => (
            <McqItemContainer
              key={idx}
              style={{ cursor: "pointer" }}
              onClick={() => this.handleEditClick(data)}
            >
              <McqDescription>{data.question}</McqDescription>
              {user.read_only == false?
              <McqToolbar>
                <img
                  src={require("static/img/edit-button-blue.png")}
                  onClick={() => this.handleEditClick(data)}
                  className="image-fluid"
                  alt="Edit icon"
                />
                <img
                  src={require("static/img/trash.png")}
                  onClick={(evt) => this.handleDeleteClick(data, evt)}
                  className="image-fluid"
                  alt="Delete icon"
                />
              </McqToolbar>:null}
            </McqItemContainer>
          ))}
      </McqSectionContainer>
    );
  }
}

export default connectStore({ activity, quiz2, onboard })(QuizMcqSection);
