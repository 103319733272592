import React, { Component } from "react";
// import { throttle, debounce } from "throttle-debounce";
import { connectStore } from "redux-box";
import { module as ui } from "@st/ui";
import { module as level } from "@st/level";
import { module as activity } from "@st/activity";
import {
  CheckBall,
  CheckBallInner,
  Chevron,
  DropdownItem,
  DropdownFlexStart,
} from "@sc/custom.styled";
import { FilterDropDownMainContainer } from "@sc/custom.styled";
import { ToolbarContainer } from "@c/users-view/MainContent.styled";
import { OptionContainer, ActiveWrapper, SVHeaderNavContainer, SVHeaderNavigationToolbar } from './StoryViewHeader.styled';
import { SVHeaderDropDown, SVHeaderDropDownMenu } from './StoryViewHeader.styled';
import { MCQCheckBoxContainer, MCQCheckBoxInnerContainer } from "@sc/McqEditor.styled";
import ReactDOM from "react-dom";
import { module as story } from "@st/story";
import Toggle from 'react-toggle';
import { FlexBetween } from "static/styled/custom.styled";
import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";


class StoryViewHeader extends Component {
  constructor(props) {
    super();
    this.state = {
      searchBarEnable: false,
      selectedCollections: [],
      selectedSources: [],
      allSelected: false,
      allSourcesSelected: false,
      unselect: false,
      displayMenu: false,
      collectionTypeSelector: false,
      sourceTypeSelector: false,
      storyStatus: '',
      dataChange: false,
      sortOrder: '',
      topStoryFilter: false,
      dateFilter: false,
      publishedFilter: false,
      mcqFilter: false,
      storyTypeStatus: '',
      currentLevel: '',
      currentDifficulty: '',
      story_source:[]
    };
  }

  componentDidMount() {
    let { story } = this.props;
    //FREAD-1157
    this.props.level.requestLevels();
    // check if collection list exists. for collection_list filter
    if (story.collection_list && story.collection_list.length === 0) {
      story.requestCollections();
      
    }

    // check if story_list exists. for sources list filter 
    if (story.story_list && story.story_list.length === 0) {
      story.requestStories();
    }

    // check if some collections are already selected or not.
    if (story.selected_collections.length > 0) {
      let selectedCollections = story.selected_collections.split(":");
      this.setState({ selectedCollections: selectedCollections });
    } 
    else this.setState({ allSelected: true, allSourcesSelected: true });

    document.addEventListener("click", this.handleCollectionClick);
    document.addEventListener("click", this.handleSourceClick);
    document.addEventListener("click", this.handleFilterClick);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleCollectionClick);
    document.removeEventListener("click", this.handleSourceClick);
    document.removeEventListener("click", this.handleFilterClick);
  }

  handleCollectionClick = evt => {
    let { story } = this.props;
    const area = ReactDOM.findDOMNode(this.refs.areaCollection);
    if (!area.contains(evt.target) && this.state.collectionTypeSelector) {
      this.setState({
        collectionTypeSelector: false
      });
      if (this.state.allSelected || this.state.unselect) {
        story.setSelectedCollections("");
        story.requestStories();
      } else if (this.state.selectedCollections.length > 0) {
        let selected_collection = "";
        let selectedCollections = this.state.selectedCollections;
        for (let i = 0; i < selectedCollections.length; i++) {
          selected_collection += selectedCollections[i];
          if (i !== selectedCollections.length - 1) selected_collection += ":";
        }
        story.setSelectedCollections(selected_collection);
        story.requestStories();
      }
      this.setState({
        unselect: false
      });
    }
  };

  handleSourceClick = evt => {
    let { story } = this.props;
    const area = ReactDOM.findDOMNode(this.refs.areaSource);
    // checking if the user clicks on the source dropdown
    if (!area.contains(evt.target) && this.state.sourceTypeSelector) {
      this.setState({
        sourceTypeSelector: false
      });
      if (this.state.allSourcesSelected || this.state.unselect) {
        story.setSelectedSources("");
        story.requestStories();
      } else if (this.state.selectedSources.length > 0) {
        let selected_sources = "";
        let selectedSources = this.state.selectedSources;
        // storing selected sources in a string.
        for (let i = 0; i < selectedSources.length; i++) {
          selected_sources += selectedSources[i];
          if (i !== selectedSources.length - 1) selected_sources += ":";
        }
        story.setSelectedSources(selected_sources);
        story.requestStories();
      }
      this.setState({
        unselect: false
      });
    }
  };

  handleCollectionSelector = val => {
    this.setState({
      collectionTypeSelector: val
    });
  };

  handleSourceSelector = val => {
    this.setState({
      sourceTypeSelector: val
    });
  };

  setDefaultState() {
    this.setState({
      dataChange: true,
    })
  }

  // methods for sorting and filtering start
  setStoryStatus = (value) => {
    this.setState({
      storyStatus: value,
      displayMenu: false,
    });
    let currentPath = window.location.pathname;
    if (currentPath === '/stories/Level%201/Easy' || currentPath === '/stories/Level%202/Easy' || currentPath === '/stories/Level%203/Easy' || currentPath === '/stories/Level%204/Easy' || currentPath === '/stories/Level%205/Easy' || currentPath === '/stories/Level%206/Easy' || currentPath === '/stories/Level%207/Easy' || currentPath === '/stories/Level%201/Hard' || currentPath === '/stories/Level%202/Hard' || currentPath === '/stories/Level%203/Hard' || currentPath === '/stories/Level%204/Hard' || currentPath === '/stories/Level%205/Hard' || currentPath === '/stories/Level%206/Hard' || currentPath === '/stories/Level%207/Hard') {
      this.props.story.setGradeLevelSort(value)
      this.props.story.requestStories();
    }
    else {
      this.props.story.setFilterLevelSearch({ ...this.props.story.filterLevelSearch, })
      this.props.story.requestStories();
    }
  };


  // method for choosing story type
  setStoryTypeStatus = (value) => {
    this.setState({
      storyTypeStatus: value,
      displayMenu: false,
    });
    let currentPath = window.location.pathname;
    if (currentPath === '/stories/Level%201/Easy' || currentPath === '/stories/Level%202/Easy' || currentPath === '/stories/Level%203/Easy' || currentPath === '/stories/Level%204/Easy' || currentPath === '/stories/Level%205/Easy' || currentPath === '/stories/Level%206/Easy' || currentPath === '/stories/Level%207/Easy' || currentPath === '/stories/Level%201/Hard' || currentPath === '/stories/Level%202/Hard' || currentPath === '/stories/Level%203/Hard' || currentPath === '/stories/Level%204/Hard' || currentPath === '/stories/Level%205/Hard' || currentPath === '/stories/Level%206/Hard' || currentPath === '/stories/Level%207/Hard') {
      this.props.story.setActiveStoryType(value)
      this.props.story.requestStories();
    }
    else {
      this.props.story.setStoryType({ ...this.props.story.storyType, })
      this.props.story.requestStories();
    }
  };

  // method for choosing story type 
  handleFilterClick = (evt) => {
    const area = ReactDOM.findDOMNode(this.refs.area);
    if (!area.contains(evt.target) && this.state.displayMenu) {
      this.setState({
        displayMenu: false
      })
    }
  };

  displayMenu = () => {
    this.setState({
      displayMenu: !this.state.displayMenu,
    })
  };
  // methods for sorting  end


  // methods for filter start
  enableStoryFilter = () => {
    let currentPath = window.location.pathname;
    this.setState({
      onChange: true,
    })
  }

  enableDateFilter = (evt) => {
    this.setState({
      dateChange: evt.target.checked,
    });
    let value = {
      type: 'date',
      payload: 'True'
    };
    this.props.story.setActiveFilter(value);
    this.props.story.requestStories();
  }

  enableTopStoryFilter = (evt) => {
    this.setState({
      topStoryFilter: evt.target.checked,
    }, () => {
      let value = {
        type: 'top_story',
        payload: 'True'
      };
      if (this.state.topStoryFilter === true) {
        this.props.story.setActiveFilter(value);
        this.props.story.requestStories();
      }
      else {
        this.props.story.unsetActiveFilter(value);
        this.props.story.requestStories();
      }
    });
  }

  enableMcqFilter = (evt) => {
    this.setState({
      mcqFilter: evt.target.checked,
    }, () => {
      let value = {
        type: 'has_mcq',
        payload: 'True'
      };
      if (this.state.mcqFilter === true) {
        this.props.story.setActiveFilter(value);
        this.props.story.requestStories();
      }
      else {
        this.props.story.unsetActiveFilter(value);
        this.props.story.requestStories();
      }
    })
  }
  enablePublishedFilter = (evt) => {
    this.setState({
      publishedFilter: evt.target.checked,
    }, () => {
      let value = {
        type: 'published',
        payload: 'True'
      };
      if (this.state.publishedFilter === true) {
       this.props.story.setActiveFilter(value);
        this.props.story.requestStories();
      }
      else {
        this.props.story.unsetActiveFilter(value);
        this.props.story.requestStories();
      }
    })
  }
  enableStoryTypeFilter = (evt) => {
    this.setState({
      storyTypeFilter: evt.target.checked,
    })
    let value = {
      type: 'story_type',
      payload: 'True'
    };
    this.props.story.setActiveFilter(value);
    this.props.story.requestStories();
  }

  // methods for filter end

  handleLevelSelector = data => {
    this.setState({
      currentLevel: data,
    })
    let { story } = this.props;
    // story.setSearchStory("");
    story.setActiveStoryGradeLevel(data);
    story.setActiveStoryLevel("Easy");
    story.requestStories();
  };
  handleClearSearch = () => {
    let { story } = this.props;
    story.setSearchStory("");
    story.requestStories();
  };

  handleStorySearch = e => {
    let { story } = this.props;
    story.setSearchStory(e.target.value);
    setTimeout(() => {
      this.props.story.requestStories();
    }, 1500);
  };

  handleGradeSelected = (val) => {
    this.setState({
      gradeSelected: val,
      displayMenu: false,
    });
    this.props.user.setFilterLevelSearch({ ...this.props.user.filterLevelSearch, grade__name__icontains: val });
    this.props.user.requestChildren();
  };

  handleCollectionChange = (selectedId, index) => {
    if (index === 0) {
      this.setState({
        allSelected: !this.state.allSelected
      });
      if (
        this.state.selectedCollections &&
        this.state.selectedCollections.length > 0
      ) {
        this.setState({
          selectedCollections: [],
          unselect: true
        });
      }
    } else {
      if (this.state.allSelected)
        this.setState({
          allSelected: false
        });

      if (
        !this.state.selectedCollections.some(
          collection => collection === selectedId
        )
      )
        this.setState({
          selectedCollections: this.state.selectedCollections.concat(selectedId)
        });
      else {
        let selectedCollections = this.state.selectedCollections.filter(
          collection => {
            return collection !== selectedId;
          }
        );
        this.setState({
          selectedCollections: selectedCollections,
          unselect: true
        });
      }
    }
  };

  // handling changes in source selector 
  handleSourcesChange = (selectedSource, index) => {
    if (index === 0) { // all sources are selected
      this.setState({
        allSourcesSelected: !this.state.allSourcesSelected
      });
      if (
        this.state.selectedSources &&
        this.state.selectedSources.length > 0
      ) {
        this.setState({
          selectedSources: [],
          unselect: true
        });
      }
    } else { 
      if (this.state.allSourcesSelected)
        this.setState({
          allSourcesSelected: false
        });

      if (
        !this.state.selectedSources.some(
          source => source === selectedSource
        )
      )
        this.setState({
          selectedSources: this.state.selectedSources.concat(selectedSource)
        });
      else {
        let selectedSources = this.state.selectedSources.filter(
          source => {
            return source !== selectedSource;
          }
        );
        this.setState({
          selectedSources: selectedSources,
          unselect: true
        });
      }
    }
  };

  // function to fetch unique stories as currently we don't have a direct model to fetch this.
  fetchUniqueSources= () => {
    return this.props.story.story_list.reduce((acc, curr)=> {
      if(acc.indexOf(curr.source)<0) {
        acc.push(curr.source);
      }
      return acc;
    }, [])
  }

  enableSearchBar = actionType => {
    if (actionType === "focus" && this.state.searchBarEnable === false)
      this.setState({
        searchBarEnable: true
      });
    else if (actionType === "blur" && this.state.searchBarEnable === true)
      this.setState({
        searchBarEnable: false
      });
  };
  handleSortOrder = (order) => {
    this.setState({
      sortOrder: order
    });
  }

  handleLevelChange= (e) => {
    const level= e.target.value;
    this.handleLevelSelector(level);
    this.props.history.push("/stories/" + level + "/Easy");
  }

  getLevel= (e) => {
    let lvl = 'Level 1'
    switch (e) {
      case "Level%201":
        lvl = 'Level 1'
      break;
      case "Level%202":
        lvl = 'Level 2'
      break;
      case "Level%203":
        lvl = 'Level 3'
      break;
      case "Level%204":
        lvl = 'Level 4'
      break;
      case "Level%205":
        lvl = 'Level 5'
      break;
      case "Level%206":
        lvl = 'Level 6'
      break;
      case "Level%207":
        lvl = 'Level 7'
      break;
      case "Level%208":
        lvl = 'Level 8'
      break;
      case "Level%209":
        lvl = 'Level 9'
      break;
      default:
        lvl = 'Level 1'
        break;
    }
    return lvl
  }

  render() {
    const data = this.props.level.levels;
    let { story } = this.props;
    let currentPath = window.location.pathname;
    currentPath.split("/")
    let pathlevel = currentPath.split("/")[2]
    let currentLevel = this.getLevel(pathlevel)
    let levelListOptions= data && data.map((level, i)=> (
      <option value={level} key={level} selected={currentLevel == level} >
        {level}
      </option>
    ));
    const {permission} = this.props.onboard;
    return (
      <SVHeaderNavContainer>
        <SVHeaderNavigationToolbar>
        <div className="input-group mx-2 my-1" style={{width: 'fit-content'}}>
          <div className="input-group-prepend">
            <label className="input-group-text" htmlFor="inputGroupSelect01">Levels</label>
          </div>
          
          <select className="custom-select" id="inputGroupSelect01" onChange={(e)=>this.handleLevelChange(e)}>
            {levelListOptions}
          </select>
        </div>
        
        <div className="input-group mx-2 my-1" style={{width: 'fit-content'}}>
          <div className="input-group-prepend">
            <label className="input-group-text" htmlFor="inputGroupSelect03">Sources</label>
          </div>
          <SVHeaderDropDown
            minWidth="180px"
            style={{ width: "180px", marginRight: "10px", padding: "5px 10px" }}
            active={this.state.sourceTypeSelector}
            ref="areaSource"
            tabIndex="1"
            id="inputGroupSelect03"
          >
            <div
              style={{ fontSize: "15px", overflow: "hidden" }}
              onClick={() =>
                this.handleSourceSelector(
                  !this.state.sourceTypeSelector
                )
              }
            >
              {this.state.allSourcesSelected
                ? "All Sources"
                : this.state.selectedSources.length +
                " Sources Selected"}
              <Chevron>
                <i className="fa fa-sort" />
              </Chevron>
            </div>
            <SVHeaderDropDownMenu
              active={this.state.sourceTypeSelector}
              color="#fff"
            >
              <DropdownItem>
                <DropdownFlexStart
                  second
                  onClick={() => this.handleSourcesChange("All", 0)}
                >
                  <CheckBall
                    second
                    style={{ marginRight: "6px" }}
                    active={this.state.allSourcesSelected}
                  >
                    <CheckBallInner second active={this.state.allSourcesSelected} />
                  </CheckBall>
                  {this.state.allSourcesSelected ? "Unselect All" : "Select All"}
                </DropdownFlexStart>
              </DropdownItem>
              {story.story_list && story.story_list.length > 0
                ? this.fetchUniqueSources().map((source, index) => (
                  <DropdownItem key={source}>
                    <DropdownFlexStart
                      second
                      onClick={() =>
                        this.handleSourcesChange(source, index + 1)
                      }
                    >
                      <CheckBall
                        second
                        style={{ marginRight: "6px" }}
                        active={
                          this.state.allSourcesSelected ||
                          this.state.selectedSources.includes(
                            source
                          )
                        }
                      >
                        <CheckBallInner
                          second
                          active={
                            this.state.allSourcesSelected ||
                            this.state.selectedSources.includes(
                              source
                            )
                          }
                        />
                      </CheckBall>
                      {source}
                    </DropdownFlexStart>
                  </DropdownItem>
                ))
                : null}
            </SVHeaderDropDownMenu>
          </SVHeaderDropDown>  
        </div>

        <div className="input-group mx-2 my-1" style={{width: 'fit-content'}}>
          <div className="input-group-prepend">
          <label className="input-group-text" htmlFor="inputGroupSelect03">Collections</label>
          </div>
          <SVHeaderDropDown
            minWidth="100px"
            style={{ width: "180px", marginRight: "10px", padding: "5px 10px" }}
            active={this.state.collectionTypeSelector}
            ref="areaCollection"
            tabIndex="1"
          >
            <div
              style={{ fontSize: "15px", overflow: "hidden" }}
              onClick={() =>
                this.handleCollectionSelector(
                  !this.state.collectionTypeSelector
                )
              }
            >
              {this.state.allSelected
                ? "All Collections"
                : this.state.selectedCollections.length +
                " Collections Selected"}
              <Chevron>
                <i className="fa fa-sort" />
              </Chevron>
            </div>
            <SVHeaderDropDownMenu
              maxHeight="150px"
              active={this.state.collectionTypeSelector}
              color="#fff"
              style={{ padding: "0px", minHeight: "40px" }}
            >
              <DropdownItem>
                <DropdownFlexStart
                  second
                  onClick={() => this.handleCollectionChange("All", 0)}
                >
                  <CheckBall
                    second
                    style={{ marginRight: "6px" }}
                    active={this.state.allSelected}
                  >
                    <CheckBallInner second active={this.state.allSelected} />
                  </CheckBall>
                  {this.state.allSelected ? "Unselect All" : "Select All"}
                </DropdownFlexStart>
              </DropdownItem>
              {story.collection_list && story.collection_list.length > 0
                ? story.collection_list.map((collection, index) => (
                  <DropdownItem key={collection.id}>
                    <DropdownFlexStart
                      second
                      onClick={() =>
                        this.handleCollectionChange(collection.id, index + 1)
                      }
                    >
                      <CheckBall
                        second
                        style={{ marginRight: "6px" }}
                        active={
                          this.state.allSelected ||
                          this.state.selectedCollections.includes(
                            collection.id
                          )
                        }
                      >
                        <CheckBallInner
                          second
                          active={
                            this.state.allSelected ||
                            this.state.selectedCollections.includes(
                              collection.id
                            )
                          }
                        />
                      </CheckBall>
                      {collection.name}
                    </DropdownFlexStart>
                  </DropdownItem>
                ))
                : null}
            </SVHeaderDropDownMenu>
          </SVHeaderDropDown>
          
        </div>  
          
        <div className="input-group mx-2 my-1" style={{width: 'fit-content'}}>
          <div className="input-group-prepend">
            <label className="input-group-text" htmlFor="inputGroupSelect02">
            {(this.props.story.search_story.length > 0) ? (
              <i
                className="fa fa-times cross"
                style={{color: 'red', cursor: 'pointer'}}
                onClick={() => this.handleClearSearch()}
              />
            ): (<i className="fa fa-search" />)
            }
            </label>
          </div>
          <input type="text" className="form-control" 
          onChange={this.handleStorySearch}
          placeholder="Search"
          value={this.props.story.search_story}
          onFocus={() => this.enableSearchBar("focus")}
          onBlur={() => this.enableSearchBar("blur")}
          aria-describedby="basic-addon1"/>
        </div>
        {Permission.getPermission(permission, "change_collection")?    
          <div className="mx-2 my-1" style={{width: 'fit-content'}}>
            <button type="button" className="btn btn-warning text-white" style={{background: '#ea882b'}}
                onClick={() => this.props.ui.showModal("COLLECTIONFORM")} >
              Manage Collections
            </button>
          </div> :null
        } 
        <div className="mx-2 my-1" style={{width: 'fit-content'}}>
        <ToolbarContainer>
            <div style={{ display: 'inline-block' }} onBlur={this.displayMenu} ref="area">
              <i className="fa fa-filter btn btn-warning text-white p-2" style={{background: '#ea882b'}} onClick={this.displayMenu}  />
              {this.state.displayMenu ? <FilterDropDownMainContainer onBlur={this.displayMenu} tabIndex="0">
                <ul>
                  <li> <i className="fa fa-sort px-1"/> <b> Sort by </b> 
                 <ul>
                      <div style={{ backgroundColor: 'white', marginRight: '2px', minHeight: '140px', minWidth: '150px', boxShadow: '0px 3px 10px -2px rgba(0,0,0,0.5)', position: 'absolute', right: 0, overflowY: 'auto' }}>
                        <li>
                          <OptionContainer
                            onClick={() => this.setStoryStatus('')}> All <MCQCheckBoxContainer
                              onClick={() => this.setStoryStatus('')}>
                              {this.state.storyStatus === '' ? <MCQCheckBoxInnerContainer /> : null}
                            </MCQCheckBoxContainer>
                          </OptionContainer>
                        </li>

                        <FlexBetween>
                          <li><OptionContainer
                            onClick={() => this.setStoryStatus('views')}> views
                              {/* <MCQCheckBoxContainer
                              onClick={() => this.setStoryStatus('True')}>
                              {this.state.storyStatus === 'views' ? <MCQCheckBoxInnerContainer /> : null}
                            </MCQCheckBoxContainer> */}
                          </OptionContainer></li>
                          {/* arrows container */}

                          <li><OptionContainer active>
                            <MCQCheckBoxContainer active={this.state.storyStatus === 'views'}
                              onClick={() => this.handleSortOrder('asc')}>
                              <ActiveWrapper onClick={() => this.setStoryStatus('views')}>
                                <i className={`fa fa-arrow-down ${this.state.storyStatus === 'views' ? 'active' : ''}`} aria-hidden="true" title="top to bottom"></i>
                              </ActiveWrapper>
                            </MCQCheckBoxContainer>
                            <MCQCheckBoxContainer active={this.state.storyStatus === '-views'}
                              onClick={() => this.handleSortOrder('desc')}>
                              <ActiveWrapper onClick={() => this.setStoryStatus('-views')}>
                                <i className={`fa fa-arrow-up ${this.state.storyStatus === '-views' ? 'active' : ''}`} aria-hidden="true" title="bottom to top"></i>
                              </ActiveWrapper>
                            </MCQCheckBoxContainer>
                          </OptionContainer></li>

                          {/* arrows container */}
                        </FlexBetween>

                        <FlexBetween>
                          <li>
                            <OptionContainer onClick={() => this.setStoryStatus('liked')}> liked
                                                {/* <MCQCheckBoxContainer onClick={() => this.setStoryStatus('False')}>
                                {this.state.storyStatus === 'liked' ?
                                  <MCQCheckBoxInnerContainer /> : null}
                              </MCQCheckBoxContainer> */}
                            </OptionContainer>
                          </li>
                          <li><OptionContainer active>
                            <MCQCheckBoxContainer active={this.state.storyStatus === 'liked'}
                              onClick={() => this.handleSortOrder('asc')}>
                              <ActiveWrapper onClick={() => this.setStoryStatus('liked')}>
                                <i className={`fa fa-arrow-down ${this.state.storyStatus === 'liked' ? 'active' : ''}`} aria-hidden="true" title="top to bottom"></i>
                              </ActiveWrapper>
                            </MCQCheckBoxContainer>
                            <MCQCheckBoxContainer active={this.state.storyStatus === '-liked'}
                              onClick={() => this.handleSortOrder('desc')}>
                              <ActiveWrapper onClick={() => this.setStoryStatus('-liked')}>
                                <i className={`fa fa-arrow-up ${this.state.storyStatus === '-liked' ? 'active' : ''}`} aria-hidden="true" title="bottom to top"></i>
                              </ActiveWrapper>
                            </MCQCheckBoxContainer>
                          </OptionContainer></li>
                        </FlexBetween>
                        <FlexBetween>
                          <li>
                            <OptionContainer onClick={() => this.setStoryStatus('completed')}> completed
                                                {/* <MCQCheckBoxContainer onClick={() => this.setStoryStatus('False')}>
                              {this.state.storyStatus === 'completed' ?
                                <MCQCheckBoxInnerContainer /> : null}
                            </MCQCheckBoxContainer> */}
                            </OptionContainer>
                          </li>
                          <li><OptionContainer active>
                            <MCQCheckBoxContainer active={this.state.storyStatus === 'completed'}
                              onClick={() => this.handleSortOrder('asc')}>
                              <ActiveWrapper onClick={() => this.setStoryStatus('completed')}>
                                <i className={`fa fa-arrow-down ${this.state.storyStatus === 'completed' ? 'active' : ''}`} aria-hidden="true" title="top to bottom"></i>
                              </ActiveWrapper>
                            </MCQCheckBoxContainer>
                            <MCQCheckBoxContainer active={this.state.storyStatus === '-completed'}
                              onClick={() => this.handleSortOrder('desc')}>
                              <ActiveWrapper onClick={() => this.setStoryStatus('-completed')}>
                                <i className={`fa fa-arrow-up ${this.state.storyStatus === '-completed' ? 'active' : ''}`} aria-hidden="true" title="bottom to top"></i>
                              </ActiveWrapper>
                            </MCQCheckBoxContainer>
                          </OptionContainer></li>
                        </FlexBetween>
                      </div>
                    </ul>
                  </li>
                  {/* UI for story type */}
                  <li> <i className="fa fa-file-o px-1"/> <b> Story type </b> 
                 <ul>
                      <div style={{ backgroundColor: 'white', marginRight: '2px', minHeight: '150px', minWidth: '100px', overflowY: 'auto', boxShadow: '0px 3px 10px -2px rgba(0,0,0,0.5)' }}>
                        <li>
                          <OptionContainer
                            onClick={() => this.setStoryTypeStatus('')}> All <MCQCheckBoxContainer
                              onClick={() => this.setStoryTypeStatus('')}>
                              {this.state.storyTypeStatus === '' ? <MCQCheckBoxInnerContainer /> : null}
                            </MCQCheckBoxContainer>
                          </OptionContainer></li>
                        <li><OptionContainer
                          onClick={() => this.setStoryTypeStatus('read')}> Read  <MCQCheckBoxContainer
                            onClick={() => this.setStoryTypeStatus('True')}>
                            {this.state.storyTypeStatus === 'read' ? <MCQCheckBoxInnerContainer /> : null}
                          </MCQCheckBoxContainer>
                        </OptionContainer></li>
                        <li>
                          <OptionContainer onClick={() => this.setStoryTypeStatus('listen')}> Listen
                                                <MCQCheckBoxContainer onClick={() => this.setStoryTypeStatus('False')}>
                              {this.state.storyTypeStatus === 'listen' ?
                                <MCQCheckBoxInnerContainer /> : null}
                            </MCQCheckBoxContainer>
                          </OptionContainer>
                        </li>
                        <li>
                          <OptionContainer onClick={() => this.setStoryTypeStatus('watch')}> Watch
                                                <MCQCheckBoxContainer onClick={() => this.setStoryStatus('False')}>
                              {this.state.storyTypeStatus === 'watch' ?
                                <MCQCheckBoxInnerContainer /> : null}
                            </MCQCheckBoxContainer>
                          </OptionContainer>
                        </li>
                      </div>
                    </ul>
                  </li>
                  {/* UI for story type */}
                  {/* {currentPath === '/stories/Level%201/Easy' || currentPath === '/stories/Level%202/Easy' || currentPath === '/stories/Level%203/Easy' || currentPath === '/stories/Level%204/Easy' || currentPath === '/stories/Level%205/Easy' || currentPath === '/stories/Level%206/Easy' || currentPath === '/stories/Level%207/Easy' || currentPath === '/stories/Level%201/Hard' || currentPath === '/stories/Level%202/Hard' || currentPath === '/stories/Level%203/Hard' || currentPath === '/stories/Level%204/Hard' || currentPath === '/stories/Level%205/Hard' || currentPath === '/stories/Level%206/Hard' || currentPath === '/stories/Level%207/Hard' */}
                  {/* ? */}
                  <li> <i className="fa fa-chevron-left px-1"/> <b> More Filters</b>
                                        <ul>
                      <div style={{ backgroundColor: 'white', marginRight: '0', width: '160px', position: 'absolute', right: 0, boxShadow: '0px 3px 10px -2px rgba(0,0,0,0.5)', overflowY: 'auto' }}>
                        <li>
                          <OptionContainer onClick={() => this.enableStoryFilter('')}
                          ><div>Top Story</div>
                            <Toggle
                              defaultChecked={this.state.dataChange}
                              checked={this.state.topStoryFilter}
                              onChange={this.enableTopStoryFilter}
                            />
                          </OptionContainer>

                        </li>
                        <li>
                          <OptionContainer onClick={() => this.enableStoryFilter('')}><div className=''>Published</div>
                            <Toggle
                              defaultChecked={this.state.dataChange}
                              checked={this.state.publishedFilter}
                              onChange={this.enablePublishedFilter} />
                          </OptionContainer>

                        </li>
                        <li>
                          <OptionContainer onClick={() => this.enableStoryFilter('')}>MCQ
                              <Toggle
                              defaultChecked={this.state.dataChange}
                              checked={this.state.mcqFilter}
                              onChange={this.enableMcqFilter} />
                          </OptionContainer>

                        </li>
                      </div>
                    </ul>
                  </li>
                  {/* : */}
                  {/* <li> Grade

                                        {this.props.activity.grades ? this.props.activity.grades && <ul>
                        <div style={{ backgroundColor: 'white', marginRight: '2px', overflowY: 'auto' }}>
                          <li><OptionContainer onClick={() => this.handleGradeSelected('')}> All
                                                <MCQCheckBoxContainer onClick={() => this.handleGradeSelected('')}>
                              {this.state.gradeSelected === '' ?
                                <MCQCheckBoxInnerContainer /> : null}
                            </MCQCheckBoxContainer>
                          </OptionContainer></li>
                          {this.props.activity.grades.map((grade) =>
                            <li key={grade.id}>
                              <OptionContainer
                                onClick={() => this.handleGradeSelected(grade.name)}> {grade.name}
                                <MCQCheckBoxContainer
                                  onClick={() => this.handleGradeSelected(grade.name)}>
                                  {this.state.gradeSelected === grade.name ?
                                    <MCQCheckBoxInnerContainer /> : null}
                                </MCQCheckBoxContainer>
                              </OptionContainer></li>
                          )}
                        </div>  </ul> : null}
                    </li>} */}
                </ul>
              </FilterDropDownMainContainer> : null}
            </div>
            {/* <img src={require("static/img/scroll_right.png")} alt="ScrollRight" onClick={this.displayMenu} /> */}
          </ToolbarContainer>
        
        </div>
        </SVHeaderNavigationToolbar>
      </SVHeaderNavContainer>
    );
  }
}

export default connectStore({
  ui,
  story,
  level,
  activity,
  onboard
})(StoryViewHeader)