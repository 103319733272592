import React, { Component } from "react";
import TagOverlayRouter from "@r/tagOverlayRouter";
import ActivityPackList from "@c/tag-view/activity-pack-list";
import { connectStore } from "redux-box";
import { module as ui } from "@st/ui";
import { module as tags } from "@st/tags";
import ActivityViewHeader from "@c/tag-view/activity-view-header/ActivityViewHeader";
import {
  MainBodyContainer,
  MainBodyContentContainer,
  PlusButton,
  PlusIcon
} from "@sc/custom.styled";
import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";
import swal from "sweetalert2";


class TagView extends Component {
  constructor(props) {
    super();
  }
  handleAddClickButton = e => {
    let loc = this.props.location;
    const {permission} = this.props.onboard;
    if (loc.pathname.includes("/tags/edit")) {
      this.props.history.push("/tags");
      this.props.tags.setActiveChildTag({});
    } else if (loc.pathname === "/tags") {
      if(Permission.getPermission(permission, "add_tag")){
        this.props.ui.showModal("TAGFORM");
      }else{
        swal({
          text: "You do not have permission.",
          type: "warning"
        });
      }
      
    }
  };

  render() {
    const isCreatorOpen = this.props.location.pathname.includes("/tags/edit");
    const {permission,user} = this.props.onboard;

    return (
      <MainBodyContainer>
        <TagOverlayRouter />
        <ActivityViewHeader />
        <MainBodyContentContainer
          style={
            this.props.location.pathname.includes("/tags/")
              ? { minHeight: "calc(100vh - 240px)" }
              : {}
          }
        >
          <ActivityPackList />
        </MainBodyContentContainer>
        { user.read_only == false ?
        <PlusButton
          hide={this.props.ui.show_modal}
          onClick={this.handleAddClickButton}
        >
          <PlusIcon
            primary
            alt="Plus Icon"
            cross={
              isCreatorOpen &&
              !this.props.location.pathname.endsWith("/tags/new")
            }
            src={require("static/img/plusicon.png")}
          />
        </PlusButton>:null}
      </MainBodyContainer>
    );
  }
}

export default connectStore({
  ui,
  tags,
  onboard
})(TagView)
