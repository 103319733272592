import styled from "styled-components";
import {ThemeButton} from "static/styled/custom.styled";



export const KidsParentHeader=styled.div`
        
        position:absolute;
        width:100%;
        z-index:100;
        background:white;
        padding:14px;
        height:50px;
         border-bottom: solid 1px rgba(67,38,102,0.1);
        margin-bottom:10px;
    
        .kids-parent-title
            {
                font-size: 20px;
                font-weight: 600;
                text-align: left;
                color: #000000;
                width:60%;
            }
        `;

export const KidsListDisplayContainer=styled.div`
            display:flex;
            flex-flow: column nowrap;
            padding-top:${props=>props.primary?'30px':'60px'};
            justify-content: ${props=>props.primary?'center':'flex-start'};
            height:200px;
            overflow-y: auto;`;

export const ViewProfileButton=ThemeButton.extend`
          height:40px;
        `;

export const KidsDetailsContainer =styled.div`
        display:flex;
        flex-flow: row wrap;
        min-height:70px;
        justify-content: space-between;
        border-bottom: solid 1px rgba(67,38,102,0.1);
        padding:10px 14px;`;

export const KidsNameGradeDetails=styled.div`
        display: inline-block;
            margin: 0px 0px 0px 64px;
        `;


export const KidsDetailFields=styled.div`
        
      
        display: inline;
        position:relative;
   img
    {
        height: 50px;
    width: 50px;
    position:absolute;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    border-radius: 100%;
     }`;

export const KidsName=styled.div`
        font-size: var(--lg-font-size);
        font-weight: 600;
        text-align: left;
        color: #000000;

`;

export const KidsGrade=KidsName.extend`

        font-weight:normal;
        font-size:var(--sm-font-size);
        `;

export const KidsListContainer=styled.div`
position:relative;
`;

export const KidsText=styled.div`
    font-size:var(--lg-font-size);
    text-align:center;
    color:rgba(0,0,0,0.6);
    `;