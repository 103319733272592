import React, { Component } from "react";
import SchoolGuidedPathRouter from "@r/schoolGuidedPathRouter";
import { SchoolGuidedPathContainer } from "./SchoolGuidedPathView.styled";
import SchoolGuidedPathList from "./school-guided-path-list/SchoolGuidedPathList";
import { PlusButton, PlusIcon } from "@sc/custom.styled";
import { connectStore } from "redux-box";
import { module as ui } from "@st/ui";
import { module as activity } from "@st/activity";
import { module as schoolGuidedPath } from "@st/schoolGuidedPath";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";
import swal from "sweetalert2";

class SchoolGuidedPathView extends Component {
  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(window.location.search);

    const id = urlParams.get("id");

    this.state = { id: id };
  }

  componentWillMount() {
    this.props.schoolGuidedPath.setFetchSchoolGuidedPathList(true);
    this.props.schoolGuidedPath.requestActiveGrade();
  }

  componentWillUnmount() {
    this.props.schoolGuidedPath.setSchoolGuidedPathList([]);
  }

  handleAddClickButton = (e) => {
    let loc = this.props.location;
    const { permission } = this.props.onboard;
    if (loc.pathname.includes("/school-guided-path/edit")) {
      this.props.history.push(`/school-guided-path?id=${this.state.id}`);
    } else if (loc.pathname.includes("/school-default-guided-path/edit")) {
      this.props.history.push("/school-default-guided-path");
    } else {
      if (Permission.getPermission(permission, "add_reading")) {
        this.props.ui.showModal("SCHOOLGUIDEDPATH");
      } else {
        swal({
          text: "You do not have permission.",
          type: "warning",
        });
      }
    }

  };
  render() {
    const {user} = this.props.onboard;
    const user_group = user.groups[0].name;
    console.log(user_group);
    const isCreatorOpen =
      this.props.location.pathname.includes("/school-guided-path/edit") ||
      this.props.location.pathname.includes("/school-default-guided-path/edit");

    return (
      <SchoolGuidedPathContainer primary>
        <SchoolGuidedPathRouter />
        <SchoolGuidedPathList location={this.props.location} />
        {user_group !== "spectator" ?
        <PlusButton
          hide={this.props.ui.show_modal}
          onClick={this.handleAddClickButton}
        >
          <PlusIcon
            primary
            alt="Plus Icon"
            cross={isCreatorOpen}
            src={require("static/img/plusicon.png")}
          />
        </PlusButton>:null}
      </SchoolGuidedPathContainer>
    );
  }
}
export default connectStore({
  ui,
  activity,
  schoolGuidedPath,
  onboard,
})(SchoolGuidedPathView);
