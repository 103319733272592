import React, { Component } from "react";
import {
  KidsDetailFields,
  KidsText,
  KidsListContainer,
  KidsDetailsContainer,
  KidsGrade,
  KidsListDisplayContainer,
  KidsName,
  KidsNameGradeDetails,
  KidsParentHeader,
  ViewProfileButton,
} from "./KidsList.styled";
import { module as user } from "@st/user";
import { module as ui } from "@st/ui";
import { connectStore } from "redux-box";
import { Link } from "react-router-dom";


class KidsList extends Component {
  componentDidMount() {
    if (this.props.user.active_parent) {
      this.props.user.requestChildrenByParentId(
        this.props.user.active_parent.id
      );
    }
  }

  componentWillUnmount() {
    this.props.user.unsetChildrenList();
  }

  viewProfile = () => {
    this.props.user.unsetChildrenList();
    console.log("unsetChildrenList", this.props.user.unsetChildrenList());

    this.props.ui.hideModal();
  };
  render() {
    const kidsList =
      this.props.user.children_list.length > 0 &&
      this.props.user.children_list.map((kid, i) => (
        <KidsDetailsContainer key={i}>
          <KidsDetailFields>
            <img
              src={
                kid.image ? kid.image : require("static/img/default-image.png")
              }
              className="image-fluid"
              alt="Profile icon"
            />
            <KidsNameGradeDetails>
              <KidsName> {kid.name}</KidsName>
              <KidsGrade> {kid.grade.name} </KidsGrade>
            </KidsNameGradeDetails>
          </KidsDetailFields>

          <Link to={"/users/children/" + kid.id}>
            <ViewProfileButton onClick={this.viewProfile}>
              {" "}
              View Profile{" "}
            </ViewProfileButton>
          </Link>
        </KidsDetailsContainer>
      ));
    console.log("kidslist", kidsList);
    return (
      <div>
        {this.props.user.active_parent || this.props.user.children_list ? (
          <KidsListContainer>
            <KidsParentHeader>
              <div className="kids-parent-title">
                {" "}
                {this.props.user.active_parent.name}'s kids
              </div>
            </KidsParentHeader>

            {this.props.user.children_list.length > 0 ? (
              <KidsListDisplayContainer>{kidsList}</KidsListDisplayContainer>
            ) : (
              <KidsListDisplayContainer primary>
                <KidsText> No Kids Added by the Parent </KidsText>
              </KidsListDisplayContainer>
            )}
          </KidsListContainer>
        ) : null}
      </div>
    );
  }
}
export default connectStore({
  user,
  ui,
})(KidsList)
