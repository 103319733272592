import React, {Component} from 'react';
import {
    CardContainer,
    FooterText,
    StyledDiv,
    StyledDivImage,
    Title
} from "@c/activity-view/activities-list/ActivityCard/ActivityCard.styled";
import { connectStore } from "redux-box";
import { module as readingChallenge} from "@st/readingChallenge";
import {CardIcon, CardIconContainer, CountOverlayContainer, CountItem} from "static/styled/custom.styled";
import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";

class ReadingChallengeCard extends Component
{
    render(){
        const readingChallengeInfo = this.props.readingChallengeInfo;
        const { permission } = this.props.onboard;
        const {user} = this.props.onboard;
        return (
            <CardContainer>
                <StyledDiv secondary>
                    <Title>{readingChallengeInfo.name}</Title>

                    <Title primary>{this.props.readingChallengeInfo && this.props.readingChallengeInfo.description && this.props.readingChallengeInfo.description.length<36 ? readingChallengeInfo.description: readingChallengeInfo.description.slice(0,40)+"..." }</Title>
                    <FooterText>
                        {this.props.readingChallengeInfo.tag !== null
                            ? readingChallengeInfo.tag
                                .slice(0, 2)
                                .map((tag, i) => <span key={i}> {tag} .&nbsp; </span>)
                            : null}
                    </FooterText>
                </StyledDiv>
                <StyledDivImage primary image={readingChallengeInfo.image}>
                    <CardIconContainer>
                        {user.read_only == false && Permission.getPermission(permission, "change_reading")? 
                            <CardIcon onClick={this.props.handlePublishClick} primary bg={readingChallengeInfo.is_published}>
                                <i className="fa fa-check"/>
                            </CardIcon>:null}
                        
                        {user.read_only == false && Permission.getPermission(permission, "delete_reading")? 
                        <CardIcon>
                            <i className="fa fa-trash"/>
                        </CardIcon>:null}

                    </CardIconContainer>

                    <CountOverlayContainer style={{width:'40px'}} primary>
                        <CountItem >
                            <i className="fa fa-sticky-note-o"/>
                            <span> {readingChallengeInfo.reading_count} </span>
                        </CountItem>
                        <CountItem>
                            <i className="fa fa-bullseye"/>
                            <span>{readingChallengeInfo.completed_count} </span>
                        </CountItem>
                        
                    </CountOverlayContainer>

                </StyledDivImage>
            </CardContainer>
        )

    }
}
export default connectStore({
    readingChallenge,onboard
})(ReadingChallengeCard)