import React, { Component } from "react";
import {
  FlexBetween,
  SubHeading,
  ThemeButton,
  UploadContainer,
  McqDescription,
  McqItemContainer,
  McqToolbar
} from "@sc/custom.styled";
import { module as story } from "@st/story";
import { module as tags } from "@st/tags";
import { connectStore } from "redux-box";
import swal from "sweetalert2";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

class McqSection extends Component {

  handleEditClick(data) {
    const { permission } = this.props.onboard;
      if(Permission.getPermission(permission, "change_story")){
      let { story } = this.props;
      story.setActiveStoryQuestion(data);
      this.props.tags.setSelectedTagList(data.tag);
      story.setStoryEditorType("mcq");
    }else{
      swal({
        text: "You do not have permission.",
        type: "warning",
      })
    }
  }

  handleCreateMcq = () => {
    let { story } = this.props;
    story.unsetActiveStoryQuestion();
    story.setStoryEditorType("mcq");
  };

  handleDeleteClick = (question, e) => {
    const { permission } = this.props.onboard;

    if(Permission.getPermission(permission, "change_story")){
    this.props.story.setStoryEditorType("");
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {
        this.props.story.requestDeleteStoryQuestion(question);
      }
    });
    e.stopPropagation();
    }else{
      swal({
        text: "You do not have permission.",
        type: "warning",
      })
    }
  };
  render() {
    //let {story} = this.props;
    let { questionList } = this.props;
    const { permission } = this.props.onboard;
    const { user } = this.props.onboard;

    return (
      <UploadContainer>
        <FlexBetween>
          <SubHeading primary> Assign MCQ</SubHeading>
          { Permission.getPermission(permission, "change_story")? 
          <ThemeButton onClick={this.handleCreateMcq}>+ Create Mcq</ThemeButton>
          :null}
        </FlexBetween>
        {questionList.map((data, idx) => (
          <McqItemContainer
            key={idx}
            style={{ cursor: "pointer" }}
            onClick={() => this.handleEditClick(data)}
          >
            <McqDescription>{data.name}</McqDescription>
            {user.read_only == false ? 
            <McqToolbar>
              <img
                onClick={() => this.handleEditClick(data)}
                src={require("static/img/edit-button-blue.png")}
                className="image-fluid"
                alt="Edit icon"
              />
              <img
                onClick={e => this.handleDeleteClick(data, e)}
                src={require("static/img/trash.png")}
                className="image-fluid"
                alt="Delete icon"
              />
            </McqToolbar>
            :null}
          </McqItemContainer>
        ))}
      </UploadContainer>
    );
  }
}

export default connectStore({
  story,
  tags,
  onboard
})(McqSection)