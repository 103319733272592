import React, { useEffect } from 'react'
import './UpdateGradeSection.css'
import Select from 'react-select'
import { connectStore } from 'redux-box'
import { module as ui } from "@st/ui";
import { module as user } from "@st/user";
import { module as activity } from "@st/activity";
import { ThemeButton } from '../../../../static/styled/custom.styled';
import { useState } from 'react';


const UpdateGradeSection = (props) => {
  const [grade, setGrade] = useState('')
  const [section, setSection] = useState('')
  const [allGrades, setAllGrades] = useState([])
  const [allSections, setAllSections] = useState([])

  useEffect(() => {

    if (props.activity.gradeList.length > 0) {
      setAllGrades(props.activity.gradeList);
    }
  }, [props.activity.gradeList]);

  const changeGrade = (selectedGrade) => {
    setAllSections([])
    setGrade(selectedGrade)
    if (props.user.active_child.school_code && props.user.active_child.section) {
      props.user.getSections({
        school: props.user.active_child.section.school,
        grade: selectedGrade.id
      })
    }
  }

  useEffect(() => {
    if (props.user.sectionsList && props.user.sectionsList.length > 0) {
      setAllSections(props.user.sectionsList);
    }
  }, [props.user.sectionsList]);

  const handleUpdate = () => {

    if (section) {
      props.user.requestUpdateChildProfile({
        id: props.user.active_child.id,
        grade: grade.id,
        school_class: section.value
      });
    }
    else {
      props.user.requestUpdateChildProfile({
        id: props.user.active_child.id,
        grade: grade.id
      });
    }

    props.ui.hideModal()

  }

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      position: "relative"
    }),
  };

  return (
    <div className='update_grade_section_container'>
      <div className='update_grade_section_body'>
        <div className='update_grade_section_title'>Update Grade</div>
        <Select
          className="dropdown-container"
          value={grade}
          onChange={changeGrade}
          options={allGrades && allGrades.length >= 1 && allGrades}
          placeholder="Select Grade"
          styles={customStyles}
        />
        {
          props.user.active_child.school_code &&
          <Select
            className="dropdown-container"
            value={section}
            onChange={(selectedSection) =>
              setSection(selectedSection)
            }
            options={allSections && allSections}
            placeholder="Select Section"
            styles={customStyles}
          />
        }

      </div>

      <div className='update_grade_section_footer'>
      <ThemeButton style={{width:'100%'}} primary onClick={() => handleUpdate()}>Update</ThemeButton>
      </div>

    </div>
  )
}

export default connectStore({
  ui, user, activity
})(UpdateGradeSection)