import { createStore } from "redux-box";
// modules
import { reducer as formReducer } from "redux-form";
import { module as ui } from "./ui";
import { module as onboard } from "./onboard";
import { module as activity } from "./activity";
import { module as caseStudy } from "./caseStudy";
import { module as assessment } from "./assessment";
import { module as user } from "./user";
import { module as readingChallenge } from "./readingChallenge";
import { module as story } from "./story";
import { module as newsFreeds } from "./newsFreeds";
import { module as notification } from "./notification";
import { module as payment } from "./payment";
import { module as experience } from "./experience";
import { module as level } from "./level";
import { module as inviteCode } from "./inviteCode";
import { module as leaderBoard } from "./leaderBoard";
import { module as tags } from "./tags";
import { module as misc } from "./misc";
import { module as category } from "./category";
import { module as quiz2 } from "./quiz2";
import { module as freadomFuture } from "./freadomFuture";
import { module as schoolGuidedPath } from "./schoolGuidedPath";
import { module as product } from "./product";
const config = {
  reducers: {
    form: formReducer,
  },
};
const modules = [
  ui,
  activity,
  experience,
  caseStudy,
  onboard,
  assessment,
  user,
  payment,
  readingChallenge,
  story,
  newsFreeds,
  notification,
  level,
  inviteCode,
  leaderBoard,
  tags,
  misc,
  category,
  quiz2,
  freadomFuture,
  schoolGuidedPath,
  product
];

export default createStore(modules, config);
