import React, { Component } from "react";
import {
  ThemeButton,
  McqSectionHeader,
  McqItemContainer,
  McqDescription,
  McqToolbar
} from "static/styled/custom.styled";
import { McqSectionContainer } from "./McqSection.styled";
import store from "store";
import action from "@st/activity/actions";
import { connectStore } from "redux-box";
import { module as activity } from "@st/activity";
import { module as tags } from "@st/tags";
import swal from "sweetalert2";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

// export default connectStore({
//   activity,
//   tags
// })
// export default 

class McqSection extends Component {
  handleEditClick = data => {
    let { permission } = this.props.onboard;
    if(Permission.getPermission(permission, "change_activity")){
      this.props.activity.setActiveMcq(data);
      this.props.tags.setSelectedTagList(data.tag);
      store.dispatch(action.setEditorType("mcq"));
    }
   
  };

  handleDeleteClick = (question, evt) => {
    this.props.activity.closeEditor();
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(result => {
      if (result.value) {
        this.props.activity.requestDeleteQuestion(question);
      }
    });
    evt.stopPropagation();
  };

  render() {
    let { activity } = this.props;
    let { permission } = this.props.onboard;
    let { user } = this.props.onboard;

    return (
      <McqSectionContainer>
        <McqSectionHeader>
          Assign MCQ
          {user.read_only == false && Permission.getPermission(permission, "change_activity")? 
          <ThemeButton
            onClick={() => {
              this.props.activity.setActiveMcq(null);
              store.dispatch(action.setEditorType("mcq"));
            }}
          >
            + Create MCQ
          </ThemeButton>:null}
        </McqSectionHeader>

        {activity.questions &&
          activity.questions.map((data, idx) => (
            <McqItemContainer
              key={idx}
              style={{ cursor: "pointer" }}
              onClick={() => this.handleEditClick(data)}
            >
              <McqDescription>{data.name}</McqDescription>
              {user.read_only == false && Permission.getPermission(permission, "change_activity")?
              <McqToolbar>
                <img
                  src={require("static/img/edit-button-blue.png")}
                  onClick={() => this.handleEditClick(data)}
                  className="image-fluid"
                  alt="Edit icon"
                />
                <img
                  src={require("static/img/trash.png")}
                  onClick={evt => this.handleDeleteClick(data, evt)}
                  className="image-fluid"
                  alt="Delete icon"
                />
              </McqToolbar>:null}
            </McqItemContainer>
          ))}
      </McqSectionContainer>
    );
  }
}
export default connectStore({
  activity,
 tags,
 onboard
 })(McqSection)