import styled from "styled-components";

export const TopImage1 = styled.img`
  margin-left: 27%;
  margin-right: 10%;
  margin-top: 0.3%;
`;

export const Topimage3 = styled.img`
  margin-top: 0.3%;
`;

export const Box = styled.h1`
  background: white;
  width: 140.1px;
  left: 315px;
  top: 69px;
  border-radius: 0px 0px 8px 8px;
  margin-right: 10%;
  display: grid;
  padding: 5px;
`;

export const Title = styled.h1`
  position: absolute;
  width: 734px;
  height: 63px;
  left: 301px;
  top: 129px;
  // font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 154.2%;
  /* or 62px */
  text-align: center;
  color: #ffffff;
`;

export const Title2 = styled.h1`
  position: absolute;
  width: 274px;
  height: 9px;
  left: 516px;
  top: 196px;

  // font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: #ffffff;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
export const Wrapper = styled.section`
  text-align: center;
  display: flex;
`;

export const TopContainer = styled.div`
  width: 100%;
  height: 234px;
  left: 800px;
  bottom: 2966px;
  background: linear-gradient(1.77deg, #9134d8 0%, #7a6fff 96.97%);
`;

export const Wrapper2 = styled.div`
  display: flex;
  width: 100%;
  height: 138px;
  left: 0px;
  top: 234px;
  background: #e9e8ff;
`;

export const Title3 = styled.h1`
  height: 30.92px;
  left: 141.47px;
  top: 267px;
  font-style: normal;
  font-weight: bold;
  font-size: 25.7669px;
  line-height: 31px;
  text-align: center;
  color: #9134d8;
`;
export const Title4 = styled.h1`
  width: 143.44px;
  height: 38.65px;
  left: 120px;
  top: 301.36px;
  font-style: normal;
  font-weight: 500;
  font-size: 15.4601px;
  line-height: 19px;
  text-align: center;
  color: #491a6c;
`;

export const Box2 = styled.div`
  padding: 26px;
  margin-left: 171px;
  text-align: center;
`;
export const Wrapper3 = styled.div`
  width: 100%;
  height: 162px;
  background: #ffffff;
`;
export const Image1 = styled.div`
  width: 364px;
  height: 0px;
  margin-top: 78px;
  border: 4px solid #e9e8ff;
`;
export const Image2 = styled.div`
  width: 364px;
  height: 0px;
  margin-top: 10px;
  border: 4px solid #e9e8ff;
`;

export const Box3 = styled.div`
  width: 40px;
`;
export const Title5 = styled.h1`
  position: absolute;
  //   width: 278px;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 31px;
  text-align: center;
  color: #491a6c;
`;
export const Box4 = styled.div`
  display: flex;
`;

export const Title6 = styled.h1`
  height: 30.92px;
  font-style: normal;
  font-weight: bold;
  font-size: 19.7669px;
  line-height: 31px;
  color: #9134d8;
  margin-left: 225px;
  width: 180px;
`;
export const Title7 = styled.h1`
  height: 57.92px;
  left: 141.47px;
  top: 267px;
  font-style: normal;
  font-weight: bold;
  font-size: 19.7669px;
  line-height: 31px;
  color: #9134d8;
  margin-left: 123px;
  width: 370px;
`;

export const Box5 = styled.div`
  margin-top: 48px;
  margin-left: 73px;
`;
export const Image3 = styled.div`
  width: 333px;
  height: 0px;
  margin-top: 78px;
  border: 4px solid #e9e8ff;
  margin-left: 104px;
`;
export const Image4 = styled.div`
  width: 333px;
  height: 0px;
  margin-top: 10px;
  border: 4px solid #e9e8ff;
  margin-left: 104px;
`;
export const Wrapper4 = styled.div`
  width: 100%;
  height: 192px;
  left: 800px;
  bottom: 2719px;
  background: linear-gradient(44.97deg, #fff1eb 0%, #bfc2ff 176.1%);
`;

export const Title8 = styled.div`
  width: 100%;
  height: 91px;
  left: 42px;
  top: 588px;
  // font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 29px;
  color: #491a6c;
  padding: 50px 0px 0px 100px;
`;

export const Title9 = styled.div`
  width: 100%;
  height: 91px;
  left: 42px;
  top: 588px;
  // font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 29px;
  color: #491a6c;
  padding: 0px 100px 50px 98px;
`;

export const Wrapper5 = styled.div`
  width: 100%;
  height: 289px;
  background: white;
`;
export const Box6 = styled.div`
  display: flex;
`;

export const Title10 = styled.div`
  height: 27px;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  color: #491a6c;
  margin-top: 40px;
  margin-left: 70px;
  white-space: nowrap;
`;
export const Image5 = styled.div`
  width: 950px;
  height: 0px;
  margin-top: 50px;
  border: 4px solid #e9e8ff;
  margin-left: 20px;
`;
export const Box7 = styled.div`
  height: 163px;
  border: 2px solid #491a6c;
  margin-left: 70px;
  margin-top: 29px;
  margin-right: 86px;
  padding: 15px;
  display: flex;
`;
export const Box8 = styled.div`
  padding: 26px;
  margin-left: 82px;
  text-align: center;
`;
export const Wrapper6 = styled.div`
  width: 100%;
  height: 115px;
  background: linear-gradient(91.37deg, #ffedba 0%, #d6fde1 122.1%);
`;
export const Title11 = styled.h1`
  font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #491a6c;
  padding: 42px;
`;
export const Wrapper7 = styled.div`
  width: 100%;
  background: #ffffff;
`;
export const Title12 = styled.div`
  height: 27px;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  color: #491a6c;
  margin-top: 40px;
  margin-left: 70px;
  width: 1100px;
`;
export const Image6 = styled.div`
  width: 800px;
  height: 0px;
  margin-top: 50px;
  border: 4px solid #e9e8ff;
  margin-left: 20px;
`;
export const Bar = styled.div`
  width: 101px;
  margin-left: 17px;
  margin-bottom: 14px;
`;
export const Box9 = styled.div`
  margin-left: 20px;
  padding: 70px 70px 0px 70px;
`;
export const Box10 = styled.div`
  display: flex;
`;
export const Input1 = styled.input`
  margin-left: 550px;
`;
export const Title13 = styled.div`
  height: 27px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #491a6c;
  margin-top: 40px;
  margin-left: 70px;
  white-space: nowrap;
`;
export const Box11 = styled.div`
    width: 90%;
    height: 86px;
    background: #BFC2FF;
    margin-left: 66px;
    margin-top: 18px;
    padding: 12px;
}
`;

export const Box12 = styled.div`
    width: 90%;
    height: 77px;
   background: #E9E8FF;
    margin-left: 66px;
    margin-top: 18px;
    padding: 12px;
}
`;
export const Wrapper8 = styled.div`
  width: 100%;
  height: 279px;
  background: linear-gradient(
    275.45deg,
    #fff1eb 0%,
    #e9e8ff 107.47%,
    #bfc2ff 107.47%
  );
  padding-top: 25px;
`;

export const Box13 = styled.div`
  margin-left: 28px;
`;
export const Title14 = styled.h1`
  width: 472px;
  height: 132px;
  left: 288px;
  top: 2921px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #491a6c;
  display: contents;
`;
export const Title15 = styled.span`
  width: 472px;
  height: 132px;
  left: 288px;
  top: 2921px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: white;
  margin-left: 33px;
  margin-right: 30px;
  background: #9134d8;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  line-height: 26px;
  display: inline-block;
  text-align: center;
  margin-right: 6px;
  margin-bottom: 16px;
`;
export const Title16 = styled.div`
  height: 27px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #491a6c;
  margin-top: 40px;
  margin-left: 33px;
  margin-bottom: 20px;
  white-space: nowrap;
`;
export const Wrapper9 = styled.div`
  width: 100%;
  height: 328px;
  left: 800px;
  bottom: -328px;
  background: linear-gradient(225.4deg, #9134d8 0%, #491a6c 93.59%);
`;

export const Title17 = styled.div`
  width: 753px;
  height: 164px;
  /* font-family: Inter; */
  font-style: normal;
  font-weight: bold;
  font-size: 32.2927px;
  line-height: 49px;
  padding: 66px;
  color: white;
  margin-left: 60px;
`;
export const Bottomimage = styled.img`
  margin-top: 2%;
  margin-left: 17%;
`;
export const Tabledata = styled.div`
  width: 88%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  margin-left: 80px;
  margin-top: 60px;
  border: 2px solid rgba(145, 52, 216, 1);
  color: rgba(73, 26, 108, 1);
`;
export const Title18 = styled.h1`
  width: 125px;
  height: 12px;
  font-weight: bold;
  font-size: 18px;
  line-height: 15px;
  /* text-align: center; */
  color: #491a6c;
  padding: 19px;
  margin-top: 11px;
`;
export const Box14 = styled.div`
  background: linear-gradient(91.37deg, #d6fde1 0%, #ffedba 122.1%);
  width: 80%;
`;
export const Title19 = styled.h1`
  font-size: 16px;
  line-height: 9px;
  color: #491a6c;
  margin-left: 77px;
`;
export const Title20 = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  color: #491a6c;
  margin-top: 40px;
  margin-left: 70px;
  /* white-space: nowrap; */
  width: 38%;
`;
export const Image7 = styled.div`
  width: 667px;
  height: 0px;
  margin-top: 50px;
  border: 4px solid #e9e8ff;
  margin-left: 20px;
`;
export const Box15 = styled.div`
  width: 90%;
  margin-left: 20px;
  padding: 48px;
  font-size: 17px;
`;

export const Image8 = styled.div`
  width: 928px;
  height: 0px;
  margin-top: 50px;
  border: 4px solid #e9e8ff;
  margin-left: 20px;
`;
export const Box16 = styled.div`
  width: 22px;
  height: 18px;

  /* font-family: Inter; */
  /* font-style: normal; */
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #324881;
  border: 2px solid #324881;
  border-radius: 5px;
  margin-top: 80px;
`;

export const Box17 = styled.div`
  padding: 156px;
  /* margin-left: -21px; */
  text-align: center;
`;
