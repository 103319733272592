import React, { Component } from "react";
import { TagsText, SourcesText, TopStoryIcon, TopStoryContainer } from "./StoryCard.styled";
import {
  CardContainer,
  StyledDiv,
  StyledDivImage
} from "@c/activity-view/activities-list/ActivityCard/ActivityCard.styled";
import {
  Title,
  CountOverlayContainer,
  CountItem
} from "static/styled/custom.styled";
import { connectStore } from "redux-box";
import { module as story } from "@st/story";
import swal from "sweetalert2";
import { CardIcon, CardIconContainer } from "static/styled/custom.styled";
import { withRouter } from "react-router-dom";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

class StoryCard extends Component {
  
  handleTopStory = e => {
    const { permission } = this.props.onboard;
    if(Permission.getPermission(permission, "change_story")){
      let { storyDetail } = this.props;
      let collection = storyDetail.collection.map(data => {
        return data.id;
      });
      this.props.story.setStoryCollectionList(collection);
      let value = null;
      let isTopStory = false;
      let storyLevel = null;
      let topStoryLevel = `${this.props.match.params.gradeLevel}:${
        this.props.match.params.level
      }`;
      if (storyDetail.top_story_levels.some(data => data === topStoryLevel)) {
        value = "remove this from ";
        storyLevel = storyDetail.top_story_levels.filter(
          data => data !== topStoryLevel
        );
        isTopStory = true;
      } else {
        value = "add this to ";
        isTopStory = false;
      }
      swal({
        text: "Are you sure that you want to " + value + " top story?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          if (isTopStory) {
            this.props.story.requestUpdateStory({
              ...storyDetail,
              storyIndex: this.props.index,
              top_story_levels: [...storyLevel]
            });
          } else {
            this.props.story.requestUpdateStory({
              ...storyDetail,
              storyIndex: this.props.index,
              top_story_levels: [...storyDetail.top_story_levels, topStoryLevel]
            });
          }
        }
      });
    }
    e.preventDefault();
  };

  handlePublishClick = e => {
    let { storyDetail } = this.props;
    let collection = storyDetail.collection.map(data => {
      return data.id;
    });
    this.props.story.setStoryCollectionList(collection);
    let value = null;
    if (storyDetail.is_published) value = "unpublish";
    else value = "publish";

    swal({
      text: "Are you sure that you want to " + value + " this story",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No"
    }).then(result => {
      if (result.value) {
        this.props.story.requestUpdateStory({
          ...storyDetail,
          is_published: !storyDetail.is_published,
          storyIndex: this.props.index
        });
      }
    });
    e.preventDefault();
  };

  render() {
    let { storyDetail } = this.props;
    const { permission } = this.props.onboard;
    const { user } = this.props.onboard;

    return (
      <CardContainer>
        <StyledDiv secondary>
          <Title primary>{storyDetail.name}</Title>
          <TagsText>
            {storyDetail.collection.slice(0, 3).map((data, i) => (
              <span key={i}> {data.name} &nbsp; </span>
            ))}
          </TagsText>
          <SourcesText> <b> Source:</b> {storyDetail.source} </SourcesText>
          <TopStoryContainer onClick={e => this.handleTopStory(e)}>
            <TopStoryIcon
              primary
              color={
                storyDetail.top_story_levels.some(
                  data =>
                    data ===
                    `${this.props.match.params.gradeLevel}:${
                      this.props.match.params.level
                    }`
                )
                  ? "#432666"
                  : "white"
              }
            >
              {storyDetail.top_story_levels.some(
                data =>
                  data ===
                  `${this.props.match.params.gradeLevel}:${
                    this.props.match.params.level
                  }`
              ) ? (
                <i className="fa fa-check" />
              ) : null}
            </TopStoryIcon>
            Top Story
          </TopStoryContainer>
        </StyledDiv>
        <StyledDivImage primary image={storyDetail.background_image}>
          <CardIconContainer>
          {user.read_only == false && Permission.getPermission(permission, "change_story")? 
            <CardIcon
              primary
              onClick={this.handlePublishClick}
              bg={storyDetail.is_published}
            >
              <i className="fa fa-check" />
            </CardIcon>:null}
            { user.read_only == false && Permission.getPermission(permission, "delete_story")? 
            <CardIcon onClick={this.props.handleDeleteClick}>
              <i className="fa fa-trash" />
            </CardIcon>:null}
          </CardIconContainer>
          <CountOverlayContainer>
            <CountItem>
              <i className="fa fa-eye" />
              <span> {storyDetail.view_count}</span>
            </CountItem>
            <CountItem>
              <i className="fa fa-heart" />
              <span> {storyDetail.like_count}</span>
            </CountItem>
            <CountItem>
              <i className="fa fa-sticky-note-o" />
              <span> {storyDetail.reading_count} </span>
            </CountItem>
          </CountOverlayContainer>
        </StyledDivImage>
      </CardContainer>
    );
  }
}
export default withRouter(connectStore({
  story,onboard
})(StoryCard));
// export default withRouter(StoryCard);
