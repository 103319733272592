import { createSagas } from "redux-box";
import GradeApi from "@s/api/grade";
import Quiz2Api from "@s/api/quiz2";
import { call, put } from "redux-saga/effects";
import swal from "sweetalert2";

const errorHandler = (err) => {
  //console.log(err);
  swal("Oops! ", err.error_message, "error");
};

export default createSagas({
  REQUEST_ACTIVE_GRADE: function*() {
    try {
      const res = yield call(GradeApi.getAllGrades);

      yield put({
        type: "REQUEST_QUIZ_LIST_BY_GRADE_ID",
        gradeId: res[0].id,
      });
      yield put({
        type: "SET_ACTIVE_QUIZ_GRADE",
        grade: res[0].id,
      });
    } catch (err) {}
  },

  REQUEST_QUIZ_LIST_BY_GRADE_ID: function*({ gradeId }) {
    try {
      yield put({
        type: "SET_FETCH_QUIZ_LIST",
        value: true,
      });
      yield put({
        type: "SET_ACTIVE_QUIZ_GRADE",
        grade: gradeId,
      });
      const gState = require("store").default.getState();
      // const search = gState.readingChallenge.search_reading;
      const res = yield call(Quiz2Api.getQuizList, gradeId, 0);
      yield put({
        type: "SET_QUIZ_RESULT",
        result: res,
      });
      if (res && res.result && res.result.length > 0)
        yield put({
          type: "SET_QUIZ_LIST",
          quizList: res.result,
        });
      yield put({
        type: "SET_FETCH_QUIZ_LIST",
        value: false,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_QUIZ_BY_ID: function*({ quizId }) {
    try {
      yield put({
        type: "FETCH_QUIZ",
        value: true,
      });

      const res = yield call(Quiz2Api.getQuizById, quizId);
      yield put({
        type: "SET_ACTIVE_QUIZ",
        activeQuiz: res,
      });

      yield put({
        type: "SET_ACTIVE_QUIZ_GRADE",
        grade: res.grade,
      });
      yield put({
        type: "FETCH_QUIZ",
        value: false,
      });
      // if (res.section_data && res.section_data.length === 0) {
      //   defaultSections.forEach(function(section) {
      //     require("store").default.dispatch({
      //       type: "ADD_NEW_READING_SECTION",
      //       section,
      //     });
      //   });
      // }
    } catch (err) {
      errorHandler(err);
    }
  },

  //..................................QUIZ....................................

  REQUEST_ALL_QUIZ_QUESTIONS: function*({ quizid }) {
    try {
      const res = yield call(Quiz2Api.getAllQuestions, quizid);
      console.log("res", res);
      yield put({
        type: "SET_ALL_QUIZ_QUESTIONS",
        questions: res,
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_CONCAT_QUIZ_CHALLENGE: function*({ offset }) {
    try {
      const gState = require("store").default.getState();
      const gradeId = gState.quiz2.active_quiz_grade;
      // const search = gState.quiz2.search_reading;
      const res = yield call(Quiz2Api.getQuizList, gradeId, offset);
      yield put({
        type: "SET_QUIZ_RESULT",
        result: res,
      });
      if (res && res.result && res.result.length > 0)
        yield put({
          type: "SET_CONCAT_QUIZ_LIST",
          quizList: res.result,
        });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_QUIZ: function*() {
    try {
      yield put({
        type: "SET_FETCH_QUIZ_LIST",
        value: true,
      });

      const gState = require("store").default.getState();
      const gradeId = gState.quiz2.active_quiz_grade;
      //const search = gState.readingChallenge.search_reading;
      const res = yield call(Quiz2Api.getQuizList, gradeId, 0);
      yield put({
        type: "SET_QUIZ_RESULT",
        result: res,
      });
      if (res && res.result && res.result.length > 0)
        yield put({
          type: "SET_QUIZ_LIST",
          quizList: res.result,
        });
    } catch (err) {
      errorHandler(err);
    } finally {
      yield put({
        type: "SET_FETCH_QUIZ_LIST",
        value: false,
      });
    }
  },

  REQUEST_CREATE_QUIZ: function*({ quiz }) {
    console.log("Quiz result",quiz)
    try {
      yield call(Quiz2Api.createQuiz, quiz);
      yield put({
        type: "REQUEST_QUIZ",
      });
    } catch (err) {
      errorHandler(err);
    }
  },

  REQUEST_UPDATE_QUIZ: function*({ quiz }) {
    try {
      const res = yield call(Quiz2Api.updateQuiz, quiz.id, quiz);
      // yield put({
      //   type: "SET_ACTIVE_QUIZ",
      //   activeQuiz: res,
      // });
      // yield put({
      //   type: "REQUEST_QUIZ",
      // });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_DELETE_QUIZ: function*({ id }) {
    try {
      yield call(Quiz2Api.deleteQuiz, id);
      yield put({ type: "REQUEST_QUIZ" });
    } catch (err) {
      errorHandler(err);
    }
  },

  //....................................Questions...................
  REQUEST_CREATE_QUIZ_QUESTION: function*({ question }) {
    try {
      yield call(Quiz2Api.createQuestion, question);
      swal("Done!", "Created question!", "success");
      console.log("question quiz", question);
      yield put({
        type: "REQUEST_ALL_QUIZ_QUESTIONS",
        quizid: question.quiz,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_UPDATE_QUIZ_QUESTION: function*({ question }) {
    try {
      const gState = require("store").default.getState();
      const questionId = gState.quiz2.active_mcq.id;

      // for (let i = 0; i < question.options.length; i++) {
      //   let option = question.options[i];
      //   yield ReadingApi.updateOption(option.id, { name: option.name });
      // }
      // delete question.options;
      yield call(Quiz2Api.updateQuestion, questionId, question);

      swal("Done!", "Updated question!", "success");
      console.log("question quiz", question);
      yield put({
        type: "REQUEST_ALL_QUIZ_QUESTIONS",
        quizid: question.quiz,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
  REQUEST_DELETE_QUIZ_QUESTION: function*({ question }) {
    try {
      yield call(Quiz2Api.deleteQuestion, question.id);
      swal("Done!", "The selected question was deleted!", "success");
      yield put({
        type: "REQUEST_ALL_QUIZ_QUESTIONS",
        quizid: question.quiz,
      });
    } catch (err) {
      errorHandler(err);
    }
  },
});
