import React, { Component } from 'react';
import { StorySectionContainer } from "@c/story-view/story-creator/StoryCreator.styled";
import { FlexStart, MediaThemedButton, SubHeading } from "static/styled/custom.styled";
import { module as story } from "@st/story";
import uploadFile from "services/api/file";
import { connectStore } from "redux-box";
import VideoSection from "@c/activity-view/activity-creator/video-section/VideoSection";
import swal from "sweetalert2";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";


 class VideoStory extends Component {
    constructor(props) {
        super();
        super();
        this.state = ({
            enableLoad: false,
            videoFileName: '',

            media: []
        });
    }






    componentDidMount() {
        let { section } = this.props;
        if (section !== null && section.content_type === 'video') {
            this.setState({
                media: section.media,
                videoFileName: section.name === 'youtube' || section.name === 'video' ? '' : section.name,
            })
        }

    }

    handleUpload = e => {
        if (e.target.files !== null) {
            let { story } = this.props;
            story.setFetchMediaLoading(true);
            this.setState({
                enableLoad: true,
            });
            this.setState({
                videoFileName: e.target.files[0].name,
            })
            //let {story}=this.props;


            if (e.target.files && e.target.files.length > 0) {
                let fileSize = e.target.files[0].size / 1024 / 1024;
                if (fileSize > 2) {
                    swal('Oops!', 'File size greater than 5MB ', 'warning')
                }
                else {
                    uploadFile(e.target.files[0]).then(res => {
                        this.setState({
                            media: [{ thumbnail_url: '', media_url: res.file }],
                            //videoFileName:e.target.files[0].name
                        });

                        this.setState({
                            enableLoad: false,
                        });
                        if (story.active_story) {
                            let newSection = {
                                content_type: 'video',
                                media: this.state.media,
                                name: this.state.videoFileName,
                                description: '',
                                story: story.active_story.id
                            };

                            if (this.props.section === null) {
                                story.requestCreateStorySection(newSection);
                            }
                            else {
                                story.requestUpdateStorySection(newSection);
                            }
                        }
                        story.setFetchMediaLoading(false);
                    });
                }
            }



        }

    };

    render() {
        let {permission}=this.props.onboard;
        return (
            <StorySectionContainer>
                <FlexStart>
                    {Permission.getPermission(permission, "change_story") ?
                    <MediaThemedButton primary> {this.state.media.length > 0 ? 'Change Video' : 'Upload Video'}
                        <input
                            type="file"
                            accept=".mp4, .avi, .mpeg, .wmv, .mov"
                            onChange={this.handleUpload}
                        />
                    </MediaThemedButton>:null}
                    {!this.state.enableLoad ?
                        <SubHeading style={{ margin: '0px', marginLeft: '12px' }}> {this.state.videoFileName} </SubHeading> :
                        <SubHeading style={{ margin: '0px', marginLeft: '12px' }}>
                            Uploading Video <img src={require("static/img/Theme-loader.gif")} alt="loader" width="32" height="32" />
                        </SubHeading>}
                </FlexStart>
                {!this.state.enableLoad ? <div style={this.state.media.length > 0 ? { marginTop: '20px' } : {}}>
                    <VideoSection media={this.state.media} />
                </div> : null}
            </StorySectionContainer>
        )
    }

}
export default connectStore({
    story,onboard
})(VideoStory)