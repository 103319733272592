import React, {Component} from 'react';
import {PanelHeader,Heading, ThemeButton} from "static/styled/custom.styled";
import {CollectionEditorMainContainer} from "./CollectionEditor.styled";
import {module as story} from "@st/story";
import {connectStore} from "redux-box";
import {ActivityModalContainer} from "static/styled/ActivityModal.styled";
import {SelectionLabels, SelectTagsListContainer,TagLabelContainer} from "@c/activity-view/activity-creator/tag-editor/TagEditor.styled";
import swal from "sweetalert2";

const errorHandler = err => {
    swal({
        text: err,
        type: "warning"});
};


 class CollectionEditor extends Component
{
    componentDidMount()
    {
        this.props.story.requestCollections();
    }
    handleCollectionTag(collection){
        let {story} = this.props;
        if(story.active_story.collection.length === 3){
            errorHandler('Cannot Assign More than three collection to Story')
        } else{
            story.requestAddStoryCollection(collection.id);
        }
    };

    render(){
        let {collectionList} = this.props;
        let {story} = this.props;

        const selectTags = collectionList && collectionList.map((collection, i) =>
            <TagLabelContainer key={i} onClick={() => this.handleCollectionTag(collection)}>
                <div>
                    {collection.name}
                </div>
            </TagLabelContainer>);
        return(
            <CollectionEditorMainContainer>
                <PanelHeader primary>
                    <Heading style={{margin:'0px'}} >  Assign Collections </Heading>
                    <ThemeButton primary onClick={()=>story.setStoryEditorType('')}>Done</ThemeButton>
                </PanelHeader>
                <ActivityModalContainer>
                    <SelectionLabels primary>
                        Select Collections from below
                    </SelectionLabels>
                    <SelectTagsListContainer>
                        {selectTags}
                    </SelectTagsListContainer>
                </ActivityModalContainer>
            </CollectionEditorMainContainer>
        )
    }
}
export default connectStore({
    story
})(CollectionEditor)
