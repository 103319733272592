import React, { Component } from "react";
import logo from "@i/Logo.png";
import { Link, withRouter } from "react-router-dom";
import { version } from "../../../../package.json";

import {
  LabelItem,
  LabelTitle,
  LogoImage,
  SideMenuMainContainer,
  // ImageContainer,
  VersionContainer,
  ScrollBarWrapper,
} from "./SideMenu.styled";
import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";
import { connectStore } from "redux-box";

const getSideMenuList = () => {
  return [
    {
      path: "/users",
      title: "Users",
      imageClick: false,
      icon: "fa fa-user-o",
      permissionLabel: ["user"],
    },
    {
      path: "/activities",
      title: "Activities",
      imageClick: false,
      icon: "fa fa-list-alt",
      permissionLabel: ["activity"],
    },
    {
      path: "/reading-challenge",
      title: "Reading Challenge",
      imageClick: false,
      icon: "fa fa-file-o",
      permissionLabel: ["reading"],
    },
    {
      path: "/stories",
      title: "Stories",
      imageClick: false,
      icon: "fa fa-th-large",
      permissionLabel: ["story", "collection"],
    },

    {
      path: "/assessments",
      title: "Assessments",
      imageClick: false,
      icon: "fa fa-bar-chart-o",
      permissionLabel: ["activity"], // pending
    },

    {
      path: "/case-studies",
      title: "Case Studies",
      imageClick: false,
      icon: "fa fa-tasks",
      permissionLabel: ["testimonial"],
    },
    {
      path: "/news-freads",
      title: "News Freads",
      imageClick: false,
      icon: "fa fa-file-text-o",
      permissionLabel: ["news fread"],
    },
    {
      path: "/notifications",
      title: "Notifications",
      imageClick: false,
      icon: "fa fa-bell-o",
      permissionLabel: ["notification"],
    },
    {
      path: "/payments/success",
      title: "Payments",
      imageClick: false,
      icon: "fa fa-credit-card",
      permissionLabel: ["payment"],
    },
    // {
    //   path: "/experiences",
    //   title: "Experiences",
    //   imageClick: false,
    //   icon: "fa fa-star",
    //   permissionLabel: [
    //     "activity experience",
    //     "story experience",
    //     "reading experience",
    //   ], // pending
    // },
    {
      path: "/invite-code",
      title: "Invite Codes",
      imageClick: false,
      icon: "fa fa-file-text-o",
      permissionLabel: ["invite code", "school code"],
    },
    {
      path: "/school-code",
      title: "Schools",
      imageClick: false,
      icon: "fa fa-graduation-cap",
      permissionLabel: ["school"],
    },
    // {
    //   path: "/flive-code",
    //   title: "Misc",
    //   imageClick: false,
    //   icon: "fa fa-video-camera",
    //   permissionLabel: [], // pending
    // },
    {
      path: "/leader-board",
      title: "Leader Board",
      imageClick: false,
      icon: "fa fa-line-chart",
      permissionLabel: [], // pending
    },
    {
      path: "/tags",
      title: "Skills",
      imageClick: false,
      icon: "fa fa-tag",
      permissionLabel: ["tag"],
    },
    {
      path: "/quiz2",
      title: "Quiz2",
      imageClick: false,
      icon: "fa fa-tag",
      permissionLabel: ["quiz"],
    },
    {
      path: "/product",
      title: "Assignments",
      imageClick: false,
      icon: "fa fa-tag",
      permissionLabel: [],
    },
    {
      path: "/reports",
      title: "Reporting",
      imageClick: false,
      icon: "fa fa-flag",
      permissionLabel: [],
    },
  ];
};

class SideMenu extends Component {
  getPath = (pathname) => {
    let globalState = require("store").default.getState();
    if (pathname === "/experiences") {
      return `/experiences/${globalState.experience.experience_type}`;
    }
    if (pathname === "/stories") {
      return (
        pathname +
        "/" +
        globalState.story.active_story_grade_level +
        "/" +
        globalState.story.active_story_level
      );
    } else if (pathname === "/notifications") {
      return pathname + "/scheduled";
    } else {
      return (
        pathname +
        "/" +
        globalState.newsFreeds.active_news_grade_level +
        "/" +
        globalState.newsFreeds.active_news_level
      );
    }
  };

  render() {
    let currentPath = window.location.pathname;
    const { permission } = this.props.onboard;
    const { user } = this.props.onboard;
    console.log("p:",permission)
    console.log("user", user);
    const username = user.name;
    console.log(username);
    const user_group = user.groups[0].name

    return (
      <SideMenuMainContainer>
        <div className="image-container">
          <LogoImage src={logo} alt="logo-icon" />
          <VersionContainer>
            v.
            {version}
          </VersionContainer>
        </div>
        <ScrollBarWrapper>
          <ul
            className="wrappers"
            style={{ overflowY: "auto", height: "calc(100vh - 120px)" }}
          >
            {getSideMenuList().map((listItem, id) => {
              if (
                ( listItem.title === "Reading Challenge" || listItem.title === "Assessments" || listItem.title === "Leader Board")  &&
                (user.read_only === true || user_group == 'publisher' || (username==='cms') )
              ) {
                console.log("yes reading ")
                return null; // Skip rendering the menu item
              }

              if( ( listItem.title === "Payments"  || listItem.title === "Schools" || listItem.title === "Invite Codes" || listItem.title === "Notifications" || listItem.title === "Case Studies")
                && (username === 'cms') ){
                  return null;
                }



              if (
                Permission.getPermissionForMenu(
                  permission,
                  listItem.permissionLabel,
                  listItem.title
                )
              ) {
                return (
                  <Link
                    to={
                      [
                        "/stories",
                        "/news-freads",
                        "/notifications",
                        "/experiences",
                        "/quiz2",
                      ].includes(listItem.path)
                        ? this.getPath(listItem.path)
                        : listItem.path
                    }
                    key={id}
                  >
                    <LabelItem
                      backgroundColor={
                        (window.location.pathname === "/" && id === 0) ||
                        currentPath.match(listItem.path) ||
                        currentPath.includes(listItem.path)
                          ? "linear-gradient(270deg,rgba(10,202,222,0),rgba(10, 202, 222, 0.3))"
                          : "transparent"
                      }
                    >
                      <i className={listItem.icon} />
                      {listItem.imageClick ? (
                        <LabelTitle primary>
                          {listItem.title.toUpperCase()}
                        </LabelTitle>
                      ) : (
                        <LabelTitle> {listItem.title.toUpperCase()} </LabelTitle>
                      )}
                    </LabelItem>
                  </Link>
                );
              } else {
                return null; // Skip rendering the menu item if user doesn't have permission
              }
            })}
          </ul>
        </ScrollBarWrapper>
      </SideMenuMainContainer>
    );
  }
}
export default withRouter(
  connectStore({
    onboard,
  })(SideMenu)
);
