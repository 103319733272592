import React, { Component } from "react";
import {
  ActivityComponentInfoContainer,
  TopNav
} from "./ActivityCreator.styled";
import {
  ComponentContainer,
  CreatorComponent,
  SubContainer
} from "@sc/Creator.styled";
import {
  SaveChangesButton,
  ThemeButton,
  LoadingContainer
} from "static/styled/custom.styled";
import ActivityInfo from "./activity-info/ActivityInfo";
import Section from "./section/Section";
import NewSection from "./new-section/NewSection";
import MediaEditor from "./media-editor/MediaEditor";
import TextEditor from "./text-editor/TextEditor";
import TagEditor from "./tag-editor/TagEditor";
import McqEditor from "./mcq-editor/McqEditor";
import McqSection from "./mcq-section/McqSection";
import ActivityLinks from "./actvity-links/ActivityLinks";
import TagsSection from "./tags-section/TagsSection";
import { module as activity } from "@st/activity";
import { connectStore } from "redux-box";
import swal from "sweetalert2";
import ExperiencesEditor from "./experiences-editor/ExperiencesEditor";
import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";
// export default connectStore({
//   activity
// })
class ActivityCreator extends Component {
  constructor(props) {
    super();
    this.state = {
      activityLevelSelector: false,
      activityEdit: true,
      showSubContainer: false,
      activityDescription: "",
      displayMenu: false,
      activityName: "New Activity Here",
      subContainerComponent: "Activity",
      sectionList: [],
      translate: "100%",
      sectionTitle: null
    };
  }

  componentWillMount() {
    const activity = this.props.activity;
    //let loc = this.props.location;
    activity.requestActivityById(this.props.match.params.id);
    activity.requestAllQuestions(this.props.match.params.id);
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({
        opacity: 1,
        translate: "0"
      });
    }, 30);
    let history = this.props.history;
    //let location=this.props.location;
    document.addEventListener("keyup", function(e) {
      if (
        e.keyCode === 27 &&
        window.location.pathname.includes("/activities/edit")
      ) {
        history.push("/activities");
      }
    });
  }

  componentWillUnmount() {
    this.props.activity.resetNewSections();
    this.props.activity.unsetActiveActivity();
    this.props.activity.unsetActiveQuestion();
    this.props.activity.closeEditor();
  }

  handleShowSubContainer = (type, title = null) => {
    this.setState({ showSubContainer: type, sectionTitle: title });
  };

  hideSubContainer = () => {
    this.setState({ showSubContainer: false });
  };
  AddSection = () => {
    this.props.activity.addNewSection();
  };

  getAllSections = () => {
    const activity = this.props.activity;
    if (activity.active_activity)
      return [
        ...activity.active_activity.sectiondata_set,
        activity.new_section
      ];
  };

  renderSections = () => {
    const activity = this.props.activity && this.props.activity.active_activity;
    const {permission} = this.props.onboard
    return (
      activity &&
      activity.section_data &&
      activity.section_data.map(
        (section, i) =>
          section && (
            <Section
              key={i}
              handleShowSubContainer={this.handleShowSubContainer}
              section={section}
              onboard={permission}
            />
          )
      )
    );
  };
  renderNewSections = () => {
    const activity = this.props.activity;
    return activity.new_sections.map((section, i) => (
      <NewSection key={i} user={this.props.onboard} section={section} />
    ));
  };

  addSection = () => {
    let { activity } = this.props;
    if (
      this.props.activity.active_activity &&
      this.props.activity.active_activity.section_data.length < 10
    )
      this.props.activity.addNewSection({
        name: ""
      });
    else {
      swal({
        text:
          "Sorry! you cannot add more than 10 sections to " +
          activity.active_activity.name,
        type: "warning"
      });
      //errorHandler("Sorry ! you cannot add more than 5 sections to activity");
    }
  };

  saveChangesBtn = () => {
    let loc = this.props.location;
    if (loc.pathname.includes("/activities/edit")) {
      this.props.history.push("/activities");
    } else if (
      loc.pathname.includes("/activities/packs") &&
      this.props.activity.active_activity_pack
    ) {
      this.props.history.push(
        "/activities/packs/" +
          this.props.match.params.gradeLevel +
          "/" +
          this.props.match.params.level +
          "/edit/" +
          this.props.activity.active_activity_pack.id
      );
    }
  };

  handleViewExperiences = () => {
    let { activity } = this.props;
    if (
      activity.active_activity &&
      activity.active_activity.experience &&
      activity.active_activity.experience.length > 0
    ) {
      activity.setEditorType("experience");
    } else {
      swal("Oops!", "No experiences added to this activity");
    }
  };

  render() {
    let { activity } = this.props;
    const {permission} =this.props.onboard
    const {user} =this.props.onboard

    return (
      <CreatorComponent>
        <ComponentContainer
          style={
            this.props.activity.active_activity &&
            this.props.activity.active_activity.activity_pack
              ? {}
              : { transform: `translateX(${this.state.translate})` }
          }
        >
          {this.props.activity.fetchingActivity ? (
            <LoadingContainer
              style={activity.active_activity ? { height: "50px" } : { top: 0 }}
            >
              <div>
                <img
                  alt="loading"
                  width="60"
                  height="60"
                  src={require("static/img/Theme-loader.gif")}
                />
              </div>
            </LoadingContainer>
          ) : null}
          <TopNav primary style={{ justifyContent: "flex-end" }}>
            {activity.active_activity ? (
              <div>
                 {Permission.getPermission(permission, "change_activity")? 
                <ThemeButton
                  onClick={this.addSection}
                  style={{ marginRight: "20px" }}
                >
                  {" "}
                  Add Section
                </ThemeButton>:null}
                { user.read_only == false && activity.active_activity ? (
                  <SaveChangesButton
                    primary
                    marginRight={"16px"}
                    onClick={this.handleViewExperiences}
                  >
                    View Experiences
                  </SaveChangesButton>
                ) : null}
              </div>
            ) : null}
          </TopNav>

          <ActivityComponentInfoContainer>
            {activity.active_activity && (
              <ActivityInfo active_activity={activity.active_activity} />
            )}

            {activity.active_activity && this.renderSections()}
            {this.renderNewSections()}

            {activity.active_activity && (
              <McqSection
                question_data={activity.questions}
                handleShowSubContainer={this.handleShowSubContainer}
              />
            )}
            {/* <ActivityLinks id={this.props.match.params.id}/> */}
            {activity.active_activity && (
              <TagsSection
                tags={activity.active_activity.tag}
                handleShowSubContainer={this.handleShowSubContainer}
              />
            )}
            {/* FREAD-1240 */}
            <ActivityLinks id={this.props.match.params.id} applink={
              activity.active_activity!=null && activity.active_activity.deeplink!=null  ?
              activity.active_activity.deeplink:""
            }/>
          </ActivityComponentInfoContainer>
        </ComponentContainer>

        {activity.current_editor_type && (
          <SubContainer active={activity.current_editor_type} style={{overflow: "auto"}}>
            {/*when title is passed it creates a new one instead of updating active_section  */}
            {activity.current_editor_type === "text" && (
              <TextEditor
                new={this.state.sectionTitle}
                onDone={this.hideSubContainer}
              />
            )}
            {/*when title is passed it creates a new one instead of updating active_section*/}
            {[
              "image",
              "audio",
              "video",
              "document",
              "file",
              "youtube",
              "media",
              "pdf",
              "epub"
            ].includes(activity.current_editor_type) && (
              <MediaEditor
                new={this.state.sectionTitle}
                type={this.state.showSubContainer}
                onDone={this.hideSubContainer}
              />
            )}
            {activity.current_editor_type === "mcq" && (
                <McqEditor
                title={this.state.sectionTitle}
                onDone={this.hideSubContainer}
              />
              
            )}
            {activity.current_editor_type === "tag" && (
              <TagEditor
                title={this.state.sectionTitle}
                onDone={this.hideSubContainer}
              />
            )}
            {activity.current_editor_type === "experience" && (
              <ExperiencesEditor onDone={this.hideSubContainer} />
            )}
          </SubContainer>
        )}
      </CreatorComponent>
    );
  }
}

// export default ActivityCreator;

export default connectStore({
  activity,onboard
 })(ActivityCreator)