import React, { Component } from "react";
import {
  UploadButton,
  UploadContainer,
} from "@c/activity-view/activity-creator/new-section/NewSection.styled";
import {
  RemoveSection,
  SectionHeading,
  SectionNameEditable,
  UploadHeaderContainer
} from "@c/activity-view/activity-creator/activity-info/ActivityInfo.styled";
import { SubHeading } from "static/styled/custom.styled";
import store from "store";
import actions from "store/story/actions";
import {module as story} from "@st/story";
import {connectStore} from "redux-box";
import { module as onboard } from "@st/onboard";




class Section extends Component {
  constructor(props) {
    super();
    this.state = {
      editable: true,
      name: "",
      removed: false
    };
  }

  componentDidMount(){
    const props = this.props;
    props.section && props.section.name &&  this.setState({
            editable : (  props.section.name.length===0)
        })
    }



  onNameChange = (e) => {
      // this.setState({ name: e.target.value })
      store.dispatch(actions.updateNewStorySection({__id: this.props.section.__id, name: e.target.value}))
  }

  disableEdit = (e) =>{
      if(e.key==="Enter"){
        this.setState({ editable: false })
      }
  }

  enableEdit =()=> {
      this.setState({ editable: true })
  }

  removeSection= () =>{
    //  this.setState({
    //      removed: true
    //  })
    store.dispatch(actions.removeNewStorySection(this.props.section))
     this.props.story.setStoryEditorType('');
  }

  startNewTextSection =() =>{
      this.props.story.setStoryEditorType("text");
    this.props.story.startCreatingStorySection("text", this.props.section );
  }
  startNewMediaSection =() =>{
      this.props.story.setStoryEditorType("media");
    this.props.story.startCreatingStorySection("media", this.props.section );
  }

  render() {
    const {section} = this.props;
    const {user} = this.props.user;


    return (
        // this.state.removed ? <div/>:
        <UploadContainer>
        <UploadHeaderContainer>
          <UploadHeaderContainer>
            {this.state.editable ? (
              <SectionHeading
                width={"45%"}
                placeholder="Enter Section Name"
                type="text"
                autoFocus
                value={section.name}
                onChange={this.onNameChange}
                onKeyDown={(e) =>this.disableEdit(e)}
              />
            ) : (
              <SectionNameEditable>
                <SubHeading primary width={(this.state.name.length + 1) * 10 + "px"} style={{minWidth:'160px'}}>
                  {this.props.section.name}
                </SubHeading>
                <img src={require("static/img/editicon.png")} alt="" className="image-fluid" onClick={this.enableEdit} />
              </SectionNameEditable>
            )}
            {user.read_only == false ?
            <RemoveSection onClick={this.removeSection}>
              Remove Section
            </RemoveSection>:null}
          </UploadHeaderContainer>
        </UploadHeaderContainer>
        <div>
          <UploadButton onClick={this.startNewTextSection}>
            <img className="img-fluid" src={require("static/img/edit.png")} alt="add notes"/> Add notes
          </UploadButton>
          <UploadButton onClick={this.startNewMediaSection}>
            <img className="img-fluid" src={require("static/img/link.png")} alt="add media"/> Add Media
          </UploadButton>
        </div>
      </UploadContainer>
    );
  }
}
export default connectStore({
  story
})(Section)