import React, { Component } from "react";
import { module as story } from "@st/story";
import { module as level } from "@st/level";
import { uploadFile } from "@s/api/file";
import swal from "sweetalert2";
import { connectStore } from "redux-box";
import {
  StoryDescriptionInfoContainer,
  StorySectionLabelsContainer,
  StoryThemeButton,
  StoryInputField,
  StoryCountItem,
  AddSectionContainer
} from "./StoryInfo.styled";
import {
  ActivityUploadImageContainer,
  GradeDescriptionLabel
} from "@c/activity-view/activity-pack-creator/activity-pack-info/ActivityPackInfo.styled";
import {
  ActivityUploadImageButton,
  ChangeImageButton,
  ImageContainer,
  HoverImageButton,
  SelectFileIn
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import {
  ImageLoader,
  ThemeButton,
  FlexBetween,
  DropDown,
  DropDownMenu,
  CheckBall,
  CheckBallInner,
  Chevron,
  DropdownItem,
  FlexStart,
  SubLevelItem
} from "@sc/custom.styled";
import {
  ActivityDescription,
  ActivityDescriptionTextArea
} from "@sc/ActivityModal.styled";
import { FixedNav } from "@sc/NavigationBar.styled";

import {
  CheckBox,
  PublishContainer
} from "static/styled/custom.styled";
import Modal from "../../../activity-view/activity-creator/modal";
import BookSummary from "../bookSummary"
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

const errorHandler = err => {
  swal({
    text: err,
    type: "warning"
  });
};


class StoryInfo extends Component {
  constructor(props) {
    super();
    this.state = {
      storyEdit: false,
      levelSelector: false,
      readingSelector: false,
      storyTypeSelector: false,
      imageChange: false,
      openModal: false,
      backgroundImageChange: false,
      enableBackgroundLoiad: false,
      levelData: [],
      levelData2: [],
      selectedLevels: [],
      enableLoad: false,
      story_info: {
        image: "",
        background_image: "",
        reading_time: "",
        author_name: "",
        name: "",
        description: "",
        source: "",
        landscape:false,
        story_type:"",
        section_data:[]
      }
    };
  }
  componentDidMount() {
    let { storyInfo } = this.props;
    let levelList = this.props.level.levels.map(level => {
      let levelData = {
        name: level,
        selected: false,
        clicked: false,
        levels: [
          { name: "Easy", selected: false },
          { name: "Hard", selected: false }
        ]
      };
      return levelData;
    });
    this.setState({
      levelData: [
        {
          name: "Select All",
          selected: false,
          clicked: false,
          levels: []
        },
        ...levelList
      ],
      selectedLevels: storyInfo.grade_level.sort(),
      story_info: {
        ...this.state.story_info,
        name: storyInfo.name,
        description: storyInfo.description,
        author_name: storyInfo.author_name,
        source: storyInfo.source,
        background_image: storyInfo.background_image,
        image: storyInfo.image,
        reading_time: storyInfo.reading_time,
        landscape:storyInfo.landscape,
        story_type:storyInfo.story_type,
        section_data:storyInfo.section_data,
      }
    });
  }
  handleImageFocus = actionType => {
    if (actionType === "over" && this.state.imageChange === false)
      this.setState({
        imageChange: true
      });
    else if (actionType === "out" && this.state.imageChange === true)
      this.setState({
        imageChange: false
      });
  };

  handleImageChange = e => {
    // e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        imageChange: false,
        enableLoad: true,
        story_info: { ...this.state.story_info, image: reader.result }
      });
    };
    reader.readAsDataURL(file);
    uploadFile(file).then(res => {
      this.setState({
        story_info: { ...this.state.story_info, image: res.file },
        enableLoad: false
      });
    });
  };

  handleBackgroundImageFocus = actionType => {
    if (actionType === "over" && this.state.backgroundImageChange === false)
      this.setState({
        backgroundImageChange: true
      });
    else if (actionType === "out" && this.state.backgroundImageChange === true)
      this.setState({
        backgroundImageChange: false
      });
  };

  handleBackgroundImageChange = e => {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        backgroundImageChange: false,
        enableBackgroundLoad: true,
        story_info: {
          ...this.state.story_info,
          background_image: reader.result
        }
      });
    };
    reader.readAsDataURL(file);
    uploadFile(file).then(res => {
      this.setState({
        story_info: { ...this.state.story_info, background_image: res.file },
        enableBackgroundLoad: false
      });
    });
  };

  handleReadingTime = data => {
    this.setState({
      readingSelector: false,
      levelSelector: false,
      storyTypeSelector: false,
      story_info: {
        ...this.state.story_info,
        reading_time: data + " mins"
      }
    });
  };

  handleLevelChange = data => {
    this.setState({
      readingSelector: false,
      storyTypeSelector: false,
      levelSelector: true
    });
    let storyLevels = [];
    if (data === "Select All" || data === "Unselect All") {
      let newLevelData = this.state.levelData.map(level => {
        let subLevels =
          level.levels.length > 0 &&
          level.levels.map(subLevel => {
            storyLevels = storyLevels.concat(level.name + ":" + subLevel.name);
            return { ...subLevel, selected: !this.state.levelData[0].selected };
          });
        if (level.name === "Select All" || level.name === "Unselect All") {
          let name =
            level.name === "Select All" ? "Unselect All" : "Select All";
          return { ...level, name: name, selected: !level.selected };
        }
        return {
          ...level,
          selected: !this.state.levelData[0].selected,
          levels: subLevels
        };
      });
      if (data === "Unselect All") storyLevels = [];
      this.setState({
        levelData: newLevelData,
        selectedLevels: storyLevels
      });
    } else {
      let newLevelData = this.state.levelData.map(level => {
        if (level.name === data) return { ...level, clicked: !level.clicked };
        return { ...level, clicked: false };
      });
      this.setState({
        levelData: newLevelData,
        story_info: {
          ...this.state.story_info,
          level: data
        }
      });
    }
  };

  hideNotificationModal =()=>{
    this.setState({openModal:false});
  }


  handleSubLevelSelector = (levelName, subLevel) => {
    let newLevelData = this.state.levelData.map(level => {
      if (level.name === levelName) {
        let val = false;
        let subLevels = level.levels.map(subLevelOption => {
          if (subLevelOption.name === subLevel.name) {
            if (subLevel.selected) {
              let storyLevels = this.state.selectedLevels.filter(
                selectedLevel => {
                  return selectedLevel !== levelName + ":" + subLevel.name;
                }
              );
              this.setState({
                selectedLevels: storyLevels
              });
            } else {
              if (
                !this.state.selectedLevels.some(
                  data => data === level.name + ":" + subLevel.name
                )
              )
                this.setState({
                  selectedLevels: this.state.selectedLevels.concat(
                    levelName + ":" + subLevel.name
                  )
                });
            }
            return { ...subLevelOption, selected: !subLevelOption.selected };
          }
          return subLevelOption;
        });
        val = subLevels && (subLevels[0].selected || subLevels[1].selected);
        return { ...level, selected: val, levels: subLevels };
      }
      return { ...level };
    });
    this.setState({
      levelData: newLevelData
    });
  };

  handleSpecLevelSelector = (evt, selectedLevel) => {
    evt.preventDefault();
    evt.stopPropagation();
    let storyLevels = this.state.selectedLevels;
    let newLevelData = this.state.levelData.map(level => {
      if (level.name === selectedLevel.name) {
        let subLevels =
          level.levels.length > 0 &&
          level.levels.map(subLevel => {
            if (level.selected) {
              if (
                storyLevels &&
                storyLevels.some(
                  data => data === level.name + ":" + subLevel.name
                )
              )
                storyLevels = storyLevels.filter(selectedLevel => {
                  return selectedLevel !== level.name + ":" + subLevel.name;
                });
            } else {
              if (
                !this.state.selectedLevels.some(
                  data => data === level.name + ":" + subLevel.name
                )
              ) {
                storyLevels = storyLevels.concat(
                  level.name + ":" + subLevel.name
                );
              }
            }
            return { ...subLevel, selected: !level.selected };
          });
        return { ...level, selected: !level.selected, levels: subLevels };
      }
      return level;
    });

    this.setState({
      levelData: newLevelData,
      selectedLevels: storyLevels
    });
  };

  setLevelData = () => {
    //let {storyInfo}=this.props;
    if (this.state.selectedLevels.length === 12)
      this.handleLevelChange("Select All");
    else {
      let newLevelData = this.state.levelData;
      this.state.selectedLevels.forEach(selectedLevel => {
        let levelSliced = selectedLevel.split(":");
        console.log(levelSliced);
        newLevelData = newLevelData.map(level => {
          if (level.name === levelSliced[0]) {
            let subLevels =
              level.levels &&
              level.levels.map(subLevel => {
                if (subLevel.name === levelSliced[1])
                  return { ...subLevel, selected: true };
                return subLevel;
              });
            return { ...level, selected: true, levels: subLevels };
          }
          return { ...level };
        });
      });

      this.setState({
        levelData: newLevelData
      });
    }
  };

  handleStoryType = data => {
    this.setState({
      levelSelector: false,
      readingSelector: false,
      storyTypeSelector: false,
      story_info: {
        ...this.state.story_info,
        storyType: data
      }
    });
  };

  handleLevelSelector = val => {
    this.setLevelData();
    this.setState({
      readingSelector: false,
      storyTypeSelector: false,
      levelSelector: val
    });
  };
  handleReadingSelector = val => {
    this.setState({
      levelSelector: false,
      storyTypeSelector: false,
      readingSelector: val
    });
  };

  handleStorySelector = val => {
    this.setState({
      levelSelector: false,
      readingSelector: false,
      storyTypeSelector: val
    });
  };

  handleNameChange = evt => {
    this.setState({
      story_info: { ...this.state.story_info, name: evt.target.value }
    });
  };
  handleViewChange= () => {
    this.setState({
      story_info: { ...this.state.story_info, landscape:  !this.state.story_info.landscape},
    });
  };

  handleAuthorNameChange = evt => {
    this.setState({
      story_info: { ...this.state.story_info, author_name: evt.target.value }
    });
  };
  handleDescriptionChange = evt => {
    this.setState({
      story_info: { ...this.state.story_info, description: evt.target.value }
    });
  };

  handleSourceChange = evt => {
    this.setState({
      story_info: { ...this.state.story_info, source: evt.target.value }
    });
  };

  handleEditBtn = () => {
    this.setState({
      storyEdit: !this.state.storyEdit
    });
  };

  handleErrorCheck = () => {
    let state = this.state;
    let str = "";
    str += state.selectedLevels.length === 0 ? "select levels, " : "";
    str +=
      state.story_info.author_name.length <= 0 ? "enter author name, " : "";
    str += state.story_info.name === "" ? "enter story name" : "";
    if (str !== "") {
      errorHandler("Please " + str);
    }
    return str;
  };

  handleDoneBtn = () => {
    let str = this.handleErrorCheck();
    if (str === "") {
      this.handleEditBtn();
      this.props.story.requestUpdateStory({
        ...this.state.story_info,
        storyIndex: this.props.storyIndex,
        grade_level: this.state.selectedLevels
      });
    }
  };

  handleLevelBtn = () => {
    let str = this.handleErrorCheck();
    let gradeLevel = this.props.story.active_story_grade_level;
    let level = this.props.story.active_story_level;
    let activeLevel = gradeLevel + ":" + level;
    if (str === "") {
      swal({
        text:
          "Are you sure that you want to remove the story from " +
          activeLevel +
          " ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      }).then(result => {
        this.handleEditBtn();
        if (result.value) {
          this.props.story.requestUpdateStory({
            ...this.state.story_info,
            grade_level: this.state.selectedLevels
          });
          this.props.history.push("/stories/" + gradeLevel + "/" + level);
        } else {
          this.setState({
            selectedLevels: this.state.selectedLevels.concat(activeLevel)
          });
          this.props.story.requestUpdateStory({
            ...this.state.story_info,
            grade_level: this.state.selectedLevels
          });
        }
      });
    }
  };

  handleSaveChangesBtn = () => {
    this.setState({
      storyEdit: false
    });
  };

  addSection = () => {
    let { story } = this.props;
    if (
      this.props.story.active_story &&
      this.props.story.active_story.section_data.length < 10
    )
      this.props.story.addNewStorySection({
        name: ""
      });
    else {
      swal({
        text:
          "Sorry! you cannot add more than 10 sections to " +
          story.active_story.name,
        type: "warning"
      });
    }
  };

  handleStoryPreview=()=>{
    this.setState({openModal:true});
  }

  handleExperienceBtn = () => {
    let { story } = this.props;
    if (
      story.active_story &&
      story.active_story.experience &&
      story.active_story.experience.length > 0
    ) {
      story.setStoryExperiencesList(story.active_story.experience);
      this.props.story.setStoryEditorType("experience");
    } else {
      swal("Oops!", "No experience added to the selected story");
    }
  };

  downloadImage = (link) => {
    console.log(link);
    fetch(link, {
      method: "GET",
      redirect: 'follow'
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
  
  download = (image) => {
    console.log('image',image)
    var element = document.createElement("a");
    var file = new Blob(
      [
        image
      ],
      { type: "image/*" }
    );
    element.href = URL.createObjectURL(file);
    element.download = "image.jpeg";
    element.click();
  };

  loadImage=(url, isSpace = true) =>{
    return new Promise((resolve) => {
      const image = new Image()
      image.onload = () => {
        resolve(image)
      }
      image.crossOrigin = 'anonymous'
      image.src = isSpace
        ? `https://${process.env.BUCKET}.${process.env.CDN}/${url}`
        : url
    })
  }

  render() {
    const state = this.state;
    const readingTimeData = [
      "Less than 2",
      "2-5 ",
      "5-10 ",
      "10-15",
      "15-20 ",
      "20-25 ",
      "25-30 ",
      "More than 30"
    ];
    const readingList = readingTimeData.map((reading, index) => (
      <a key={index} onClick={() => this.handleReadingTime(reading)}>
        {reading}
      </a>
    ));
    let activeLevel =
      this.props.story.active_story_grade_level +
      ":" +
      this.props.story.active_story_level;
    const {permission} = this.props.onboard;
    const {user} = this.props.onboard;


    
    return (
      <FixedNav shadow style={{ height: "205px" }}>
        <StoryDescriptionInfoContainer
          height={this.state.storyEdit ? "200px" : "160px"}
        >
          {this.state.story_info.image === "" ? (
            <ActivityUploadImageContainer>
              <ActivityUploadImageButton type="button">
                <img
                  src={require("static/img/cloud-upload-icon.png")}
                  alt="Upload"
                />
                <span>Upload Image </span>
              </ActivityUploadImageButton>

              <SelectFileIn
                type="file"
                onChange={e => this.handleImageChange(e)}
              />
            </ActivityUploadImageContainer>
          ) : this.state.enableLoad ? (
            <ActivityUploadImageContainer>
              <ImageLoader>
                <img
                  alt="loading"
                  width="30"
                  height="30"
                  src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
                />
              </ImageLoader>
            </ActivityUploadImageContainer>
          ) : (
            <ActivityUploadImageContainer
              onMouseOver={() => this.handleImageFocus("over")}
              onMouseOut={() => this.handleImageFocus("out")}
            >
              <ImageContainer src={this.state.story_info.image} />
              {state.storyEdit ? (
                <ChangeImageButton
                  top={this.state.imageChange ? "82%" : "100%"}
                  opacity={this.state.imageChange ? 1 : 0}
                >
                  Change Image
                </ChangeImageButton>
              ) :null}
               {/* <ChangeImageButton
                  top={this.state.imageChange ? "82%" : "100%"}
                  opacity={this.state.imageChange ? 1 : 0}
                  onClick={() => this.canvasToImg()}
                >
                  Download Image
                </ChangeImageButton> */}

              {state.storyEdit ? (
                <SelectFileIn
                  type="file"
                  onChange={e => this.handleImageChange(e)}
                />
              ) : null}
            </ActivityUploadImageContainer>
          )}
          {this.state.story_info.background_image === "" ? (
            <ActivityUploadImageContainer width="200px" marginLeft="14px">
              <ActivityUploadImageButton type="button">
                <img
                  src={require("static/img/cloud-upload-icon.png")}
                  alt="Upload"
                />
                <span>Upload Fullscreen Image</span>
              </ActivityUploadImageButton>
              {this.state.storyEdit ? (
                <SelectFileIn
                  width="180px"
                  type="file"
                  onChange={e => this.handleBackgroundImageChange(e)}
                />
              ) : null}
            </ActivityUploadImageContainer>
          ) : this.state.enableBackgroundLoad ? (
            <ActivityUploadImageContainer width="200px" marginLeft="14px">
              <ImageLoader>
                {" "}
                <img
                  alt="loading"
                  width="30"
                  height="30"
                  src="http://photokonkurs.russia.travel/images/icons/preloader.gif"
                />
              </ImageLoader>
            </ActivityUploadImageContainer>
          ) : (
            <ActivityUploadImageContainer
              width="200px"
              marginLeft="14px"
              onMouseOver={() => this.handleBackgroundImageFocus("over")}
              onMouseOut={() => this.handleBackgroundImageFocus("out")}
            >
              <ImageContainer src={this.state.story_info.background_image} />
              {state.storyEdit ? (
                <ChangeImageButton
                  top={this.state.backgroundImageChange ? "82%" : "100%"}
                  opacity={this.state.backgroundImageChange ? 1 : 0}
                >
                  Change Background
                </ChangeImageButton>
              ) : null}
              {state.storyEdit ? (
                <SelectFileIn
                  type="file"
                  onChange={e => this.handleBackgroundImageChange(e)}
                />
              ) : null}
            </ActivityUploadImageContainer>
          )}
          {!this.state.storyEdit && (
            <FlexBetween
              style={{
                position: "absolute",
                bottom: 0,
                width: "30%",
                alignItems: "flex-end"
              }}
            >
              <StoryCountItem>
                <span> {this.props.storyInfo.like_count} </span>
                <i className="fa fa-heart" />
              </StoryCountItem>
              <StoryCountItem>
                <span> {this.props.storyInfo.view_count} </span>
                <i className="fa fa-eye" />
              </StoryCountItem>
              <StoryCountItem>
                <span> {this.props.storyInfo.reading_count} </span>
                <i className="fa fa-sticky-note-o" />
              </StoryCountItem>
            </FlexBetween>
          )}
          <ActivityDescription height="auto">
            {!state.storyEdit ? (
              <StorySectionLabelsContainer>
                <GradeDescriptionLabel>
                  <span>
                    {this.state.selectedLevels.length + " "}
                    Levels Selected |{" "}
                    {this.props.story.active_story_grade_level}(
                    {this.props.story.active_story_level}) |{" "}
                    {this.state.story_info.reading_time}
                  </span>
                  {user.read_only == false && Permission.getPermission(permission, "change_story")? 
                  <img
                    src={require("static/img/editicon.png")}
                    alt="edit icon"
                    className="image-fluid"
                    onClick={this.handleEditBtn}
                  />:null}
                </GradeDescriptionLabel>
                {user.read_only == false?
                <GradeDescriptionLabel primary style={{ width: "40%" }}>
                  <FlexBetween style={{marginBottom:10}}>
                    <StoryThemeButton
                      primary
                      onClick={this.handleExperienceBtn}
                    >
                      View Experiences
                    </StoryThemeButton>
                  </FlexBetween>
                  <br></br>
                  <FlexBetween>
                    <StoryThemeButton
                      primary
                      onClick={this.handleStoryPreview}
                    >
                      Preview
                    </StoryThemeButton>
                  </FlexBetween>
                </GradeDescriptionLabel>:null}
              </StorySectionLabelsContainer>
            ) : (
              <StorySectionLabelsContainer>
                <FlexBetween
                  style={{ width: "80%", justifyContent: "flex-start" }}
                >
                  <DropDown
                    minWidth="160px"
                    active={this.state.levelSelector}
                    onBlur={() => this.handleLevelSelector(false)}
                    tabIndex="1"
                  >
                    <div
                      style={{ fontSize: "14px", height: "24px" }}
                      onClick={() =>
                        this.handleLevelSelector(!this.state.levelSelector)
                      }
                    >
                      {this.state.selectedLevels.length >= 18
                        ? "All "
                        : this.state.selectedLevels.length + " "}
                      Levels Selected
                      <Chevron>
                        <img
                          src={require("static/img/downarrowicon.png")}
                          alt="down-arrow"
                        />
                      </Chevron>
                    </div>
                    <DropDownMenu
                      maxHeight="150px"
                      active={this.state.levelSelector}
                      color="#fff"
                      style={{ padding: "0px" }}
                    >
                      {this.state.levelData.map((levelData, index) => (
                        <DropdownItem key={index}>
                          <FlexStart
                            style={{
                              width: "100%",
                              padding: "6px 8px",
                              position: "relative",
                              borderBottom: "solid 1px #e6e6e6"
                            }}
                            onClick={() =>
                              this.handleLevelChange(levelData.name)
                            }
                          >
                            <CheckBall
                              onClick={evt =>
                                //FREAD-1182
                          (levelData.name === "Select All" || levelData.name === "Unselect All")?
                          this.handleLevelChange(levelData.name)
                               : this.handleSpecLevelSelector(evt, levelData)
                              }
                              style={{ marginRight: "6px" }}
                              active={levelData.selected}
                            >
                              <CheckBallInner active={levelData.selected} />
                            </CheckBall>
                            {levelData.levels.length > 0 && (
                              <Chevron
                                cross={
                                  levelData.clicked
                                    ? "rotate(0deg)"
                                    : "rotate(-90deg)"
                                }
                              >
                                <img
                                  src={require("static/img/downarrowicon.png")}
                                  alt="down-arrow"
                                />
                              </Chevron>
                            )}
                            {levelData.name}
                          </FlexStart>
                          {levelData.clicked &&
                            levelData.levels.length > 0 &&
                            levelData.levels.map((subLevel, i) => (
                              <SubLevelItem
                                key={i}
                                style={{
                                  backgroundColor: "#faf7ff",
                                  width: "100%",
                                  padding: "6px 8px 6px 32px",
                                  justifyContent: "flex-start",
                                  borderBottom: "solid 1px #e6e6e6"
                                }}
                                onClick={() => {
                                  this.handleSubLevelSelector(
                                    levelData.name,
                                    subLevel
                                  );
                                }}
                              >
                                <CheckBall
                                  style={{ marginRight: "6px" }}
                                  active={subLevel.selected}
                                >
                                  <CheckBallInner active={subLevel.selected} />
                                </CheckBall>{" "}
                                {subLevel.name}
                              </SubLevelItem>
                            ))}
                        </DropdownItem>
                      ))}
                    </DropDownMenu>
                  </DropDown>

                  <DropDown
                    style={{ marginLeft: "14px" }}
                    minWidth="180px"
                    active={this.state.readingSelector}
                    onBlur={() => this.handleReadingSelector(false)}
                    tabIndex="1"
                  >
                    <div
                      style={{ fontSize: "14px", height: "24px" }}
                      onClick={() =>
                        this.handleReadingSelector(!this.state.readingSelector)
                      }
                    >
                      {this.state.story_info.reading_time.length > 0
                        ? this.state.story_info.reading_time
                        : "Reading Time (min)"}
                      <Chevron>
                        <img
                          src={require("static/img/downarrowicon.png")}
                          alt="down-arrow"
                        />
                      </Chevron>
                    </div>
                    <DropDownMenu
                      maxHeight="100px"
                      active={this.state.readingSelector}
                    >
                      {readingList}
                    </DropDownMenu>
                  </DropDown>
                </FlexBetween>
                {!this.state.selectedLevels.some(
                  data => data === activeLevel
                ) ? (
                  <ThemeButton primary onClick={this.handleLevelBtn}>
                    Done
                  </ThemeButton>
                ) : (
                  <ThemeButton primary onClick={this.handleDoneBtn}>
                    Done
                  </ThemeButton>
                )}
              </StorySectionLabelsContainer>
            )}
            {!state.storyEdit ? (
              <span>{this.state.story_info.landscape? "Landscape": "Potrait"} </span>
            ):(
              <PublishContainer>
                <CheckBox active={this.state.story_info.landscape} onClick={() =>this.handleViewChange()}>
                    <i className="fa fa-check" />
                  </CheckBox>
                  <span style={{color:"black"}}>
                    {" "}Landscape{" "}
                  </span>
              </PublishContainer> 
            )}
            <StoryInputField
              height="32px"
              primary
              placeholder="New Story"
              border={
                !state.storyEdit ? "none" : "solid 1px rgba(40,50,112,0.1)"
              }
              fontSize={this.state.storyEdit ? "14px" : "18px"}
              id="activityPackName"
              value={state.story_info.name}
              width={state.storyEdit ? "75%" : "100%"}
              left={state.storyEdit ? "6px" : "0px"}
              margin={state.storyEdit ? "4px 0px " : "0px"}
              onChange={this.handleNameChange}
              disabled={!this.state.storyEdit}
            />
            <FlexBetween
              style={{ justifyContent: "flex-start", fontSize: "14px" }}
            >
              <StoryInputField
                fontSize="14px"
                height={state.storyEdit ? "30px" : "24px"}
                primary
                margin={state.storyEdit ? "4px 0px  " : "0px"}
                placeholder="Author name"
                border={
                  !state.storyEdit ? "none" : "solid 1px rgba(40,50,112,0.1)"
                }
                value={state.story_info.author_name}
                width={
                  state.storyEdit
                    ? "36%"
                    : this.state.story_info.author_name.length * 8 + "px"
                }
                left={state.storyEdit ? "6px" : "0px"}
                onChange={this.handleAuthorNameChange}
                disabled={!this.state.storyEdit}
              />
              {!this.state.storyEdit ? "  |  Source : " : ""}
              <StoryInputField
                fontSize="14px"
                height={state.storyEdit ? "30px" : "24px"}
                primary
                margin={state.storyEdit ? "4px 0px 4px 18px" : "0px"}
                placeholder="Author name"
                border={
                  !state.storyEdit ? "none" : "solid 1px rgba(40,50,112,0.1)"
                }
                value={state.story_info.source}
                width={state.storyEdit ? "36%" : "30%"}
                left={state.storyEdit ? "6px" : "0px"}
                onChange={this.handleSourceChange}
                disabled={!this.state.storyEdit}
              />
            </FlexBetween>
            <FlexBetween>
              <ActivityDescriptionTextArea
                primary={state.storyEdit}
                height={"60px"}
                value={state.story_info.description}
                placeholder="Add description"
                onChange={this.handleDescriptionChange}
              />
            </FlexBetween>
            {Permission.getPermission(permission, "change_story") ?
              <AddSectionContainer>
                {this.props.story.current_story_main_type === "sectioned" && (
                  <StoryThemeButton width="128px" onClick={this.addSection}>
                    Add Section
                  </StoryThemeButton>
                )}
              </AddSectionContainer> : null}
          </ActivityDescription>
        </StoryDescriptionInfoContainer>
        {this.state.openModal ? <Modal
          id="media-modal"
          size='lg'
          isOpen={this.state.openModal}
          onClose={this.hideNotificationModal}
        >
          <BookSummary
            data={this.state.story_info}
            //  questions={this.state.activeStoryQuestion}
            //  openSuccessModal={this.openSuccessModal}
            close={this.hideNotificationModal} />
        </Modal> : null}

      </FixedNav>
    );
  }
}

export default connectStore({
  story,
  level,
  onboard
})(StoryInfo)
