import React, { Component } from "react";
import {
  SchoolInputField,
  AddButtonContainer,
  CheckBoxContainer,
} from "./SchoolCreatorForm.styled";
import {
  ActivityContinueButtonContainer,
  ActivityMainFormContainer,
  CreateActivityContainer,
} from "@c/activity-view/activity-creation-form/ActivityCreationForm.styled";
import { ThemeButton } from "@sc/custom.styled";
import { module as ui } from "@st/ui";
import { module as inviteCode } from "@st/inviteCode";
import { module as product } from "@st/product";
import { connectStore } from "redux-box";
import swal from "sweetalert2";
import DatePicker from "react-datepicker";
import {
  DateSelector,
  DateSelectorIcon,
} from "../../flive-code-view/renewalold-school/RenewalOldSchool.styled";
import moment from "moment";
import Select from "react-select";
import CheckBox from "./CheckBox";
import SchoolGuidedPathEnable from "./SchoolGuidedPathEnable";
import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";

const schoolTagList = [
  { value: "B2B", label: "B2B" },
  { value: "Club1BR", label: "Club1BR" },
  { value: "FLIVE", label: "FLIVE" },
  { value: "Stones2Milestones", label: "Stones2Milestones" },
];


class SchoolCreatorForm extends Component {
  constructor(props) {
    super();
    this.state = {
      enableLoad: false,
      checkSuccess: false,
      schoolTagSelected: null,
      school_info: {
        name: null,
        email: null,
        phone: null,
        expiry_date: moment().format("YYYY-MM-DD"),
        license_count: null,
        school_tag: null,
        academic: {
          start_date: moment().format("YYYY-MM-DD"),
          end_date: moment()
            .add(1, "years")
            .format("YYYY-MM-DD"),
        },
        cmf_start_date: moment().format("YYYY-MM-DD"),
        cmf_end_date: moment()
          .add(1, "years")
          .format("YYYY-MM-DD"),

        is_eligible_for_test: false,
        // {
        //     "country": null,
        //     "state": null,
        //     "pincode": null,
        //     "street_address": null,
        //     "city": null
        // }
      },
      showAdress: false,
    };
  }

  componentWillReceiveProps(nextProps) { }

  componentDidMount() {
    let { schoolEditInfo } = this.props.inviteCode;

    if (schoolEditInfo && schoolEditInfo.id) {
      let sd = {
        ...schoolEditInfo,
        expiry_date: schoolEditInfo.expiry_date
          ? schoolEditInfo.expiry_date
          : moment().format("YYYY-MM-DD"),
        academic: {
          start_date:
            schoolEditInfo.academic && schoolEditInfo.academic.start_date
              ? schoolEditInfo.academic.start_date
              : moment().format("YYYY-MM-DD"),
          end_date:
            schoolEditInfo.academic && schoolEditInfo.academic.end_date
              ? schoolEditInfo.academic.end_date
              : moment()
                .add(1, "years")
                .format("YYYY-MM-DD"),
        },
        cmf_start_date: schoolEditInfo.cmf_start_date
          ? schoolEditInfo.cmf_start_date
          : moment().format("YYYY-MM-DD"),
        cmf_end_date: schoolEditInfo.cmf_end_date
          ? schoolEditInfo.cmf_end_date
          : moment()
            .add(1, "years")
            .format("YYYY-MM-DD"),
      };
      if (schoolEditInfo.school_tag) {
        this.setState({
          schoolTagSelected: {
            value: schoolEditInfo.school_tag,
            label: schoolEditInfo.school_tag,
          },
        });
      }
      if (
        schoolEditInfo.address &&
        Object.keys(schoolEditInfo.address).length > 0
      ) {
        sd["address"] = {
          country:
            schoolEditInfo.address && schoolEditInfo.address.country
              ? schoolEditInfo.address.country
              : null,
          state:
            schoolEditInfo.address && schoolEditInfo.address.state
              ? schoolEditInfo.address.state
              : null,
          pincode:
            schoolEditInfo.address && schoolEditInfo.address.pincode
              ? schoolEditInfo.address.pincode
              : null,
          street_address:
            schoolEditInfo.address && schoolEditInfo.address.street_address
              ? schoolEditInfo.address.street_address
              : null,
          city:
            schoolEditInfo.address && schoolEditInfo.address.city
              ? schoolEditInfo.address.city
              : null,
        };
      }
      this.setState({
        school_info: { ...sd },
        showAdress: schoolEditInfo.address ? true : false,
      });
    } else {
      this.props.inviteCode.enableSchoolEdit(true);
    }
  }

  componentWillUnmount() {
    let { inviteCode } = this.props;
    // if(caseStudy.active_case_study)
    // {
    //     caseStudy.setActiveCaseStudy(null);
    // }
    inviteCode.setEditSchool(null);
    this.props.inviteCode.enableSchoolEdit(false);
    // caseStudy.enableCaseStudyEdit(false);
  }

  onDatepickerRef(el) {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }

  updateSchoolGuidedPath = async () => {
    let { schoolGuidedPathFormData } = this.props.inviteCode;

    if (
      schoolGuidedPathFormData &&
      schoolGuidedPathFormData.isEnableSchoolGuidedPath
    ) {
      if (
        schoolGuidedPathFormData.selectedGrade &&
        schoolGuidedPathFormData.selectedGrade.length > 0 &&
        schoolGuidedPathFormData.selectedDate
      ) {
        let schoolGuidedPathObj = {
          school: this.props.inviteCode.schoolEditInfo.id,
          grade_ids: schoolGuidedPathFormData.selectedGrade,
          guided_path: schoolGuidedPathFormData.guided_path,
          start_date: schoolGuidedPathFormData.selectedDate,
          is_deleted: false,
        };
        this.setState({
          school_info: {
            ...this.state.school_info,
            school_guided_path: schoolGuidedPathObj,
          },
        });
        console.log("school_info", {
          ...this.state.school_info,
          school_guided_path: schoolGuidedPathObj,
        });
        this.props.inviteCode.updateSchoolGuidedPath(schoolGuidedPathObj);
        let { schoolEditInfo } = this.props.inviteCode;
        this.props.inviteCode.updateSchool(schoolEditInfo.id, {
          ...this.state.school_info,
          school_guided_path: schoolGuidedPathObj,
        });
        return true;
      } else {
        swal(
          "Oops!",
          "You must enter date and grade to enable for Assignments",
          "error"
        );
        return false;
      }
    } else {
      if (this.props.inviteCode.schoolEditInfo.school_guided_path) {
        const isSchoolGuideUpdated = await this.props.inviteCode.updateSchoolGuidedPath(
          {
            school: this.props.inviteCode.schoolEditInfo.id,
            grade_ids: this.props.inviteCode.schoolEditInfo.school_guided_path
              .grade_ids,
            guided_path: this.props.inviteCode.schoolEditInfo.school_guided_path
              .guided_path,
            start_date: this.props.inviteCode.schoolEditInfo.school_guided_path
              .start_date,
            is_deleted: true,
          }
        );
        let { schoolEditInfo } = this.props.inviteCode;
        this.props.inviteCode.updateSchool(schoolEditInfo.id, {
          ...this.state.school_info,
          school_guided_path: {
            school: this.props.inviteCode.schoolEditInfo.id,
            grade_ids: this.props.inviteCode.schoolEditInfo.school_guided_path
              .grade_ids,
            guided_path: this.props.inviteCode.schoolEditInfo.school_guided_path
              .guided_path,
            start_date: this.props.inviteCode.schoolEditInfo.school_guided_path
              .start_date,
            is_deleted: true,
          },
        });
        return isSchoolGuideUpdated;
      } else {
        let { schoolEditInfo } = this.props.inviteCode;

        this.props.inviteCode.updateSchool(schoolEditInfo.id, {
          ...this.state.school_info,
        });
      }
      return true;
    }
  };

  handleUpdateBtn = () => {
    if (this.updateSchoolGuidedPath()) {
      this.props.ui.hideModal();
      this.props.inviteCode.enableSchoolEdit(false);
    }
  };

  handleSchoolInfo = (type, evt) => {
    this.setState({
      school_info: { ...this.state.school_info, [type]: evt.target.value },
    });
  };

  // handleDescriptionChange=evt=>{
  //     this.setState({
  //         new_case_study: { ...this.state.new_case_study, description: evt.target.value }
  //     });
  // };
  // handleAuthorNameChange=evt=>{
  //     this.setState({
  //         new_case_study: { ...this.state.new_case_study, author_name: evt.target.value }
  //     });
  // };

  // handleImageFocus = actionType => {
  //     if (actionType === "over" && this.state.imageChange ===false)
  //         this.setState({
  //             imageChange: true
  //         });
  //     else if (actionType === "out" && this.state.imageChange === true)
  //         this.setState({
  //             imageChange: false
  //         });
  // };

  // _handleImageChange = e => {
  //     e.preventDefault();

  //     let reader = new FileReader();
  //     let file = e.target.files[0];
  //     let fileSize = e.target.files[0].size / 1024 / 1024;
  //     if (fileSize > 2) {
  //       swal('Oops!', 'File size greater than 2MB ', 'warning')
  //     }
  //     else {
  //     reader.onloadend = () => {

  //         this.setState({
  //             imageChange:false,
  //             enableLoad:true,
  //                 new_case_study: { ...this.state.new_case_study, author_image: reader.result }
  //         });
  //     };
  //     reader.readAsDataURL(file);
  //     uploadFile(file).then(res => {
  //         this.setState({
  //             new_case_study: { ...this.state.new_case_study, author_image: res.file },
  //             enableLoad:false,
  //         });
  //     });
  // }
  // };

  handleContinueBtn = async () => {
    this.setState({
      displayMenu: false,
    });
    if (
      this.state.school_info.name &&
      this.state.school_info.license_count &&
      this.state.school_info.expiry_date
    ) {
      await this.props.inviteCode.createSchool(this.state.school_info);
      await this.props.ui.hideModal();
    } else {
      swal(
        "Oops!",
        "You must enter case School name , expiry date and license count to create School",
        "error"
      );
    }
  };

  changeSchoolTag = (tag) => {
    this.setState({
      school_info: { ...this.state.school_info, school_tag: tag.value },
      schoolTagSelected: tag,
    });
  };
  handleEditBtn = () => {
    this.props.inviteCode.enableSchoolEdit(true);
  };

  handleExpiryDateChange = (date) => {
    this.setState({
      school_info: {
        ...this.state.school_info,
        expiry_date: moment(date).format("YYYY-MM-DD"),
      },
    });
  };
  handleAcedmicStartDateChange = (date) => {
    this.setState({
      school_info: {
        ...this.state.school_info,
        academic: {
          start_date: moment(date).format("YYYY-MM-DD"),
          end_date: this.state.school_info.academic.end_date,
        },
      },
    });
  };
  handleAcedmicEndDateChange = (date) => {
    this.setState({
      school_info: {
        ...this.state.school_info,
        academic: {
          end_date: moment(date).format("YYYY-MM-DD"),
          start_date: this.state.school_info.academic.start_date,
        },
      },
    });
  };
  handleCM_fluencyStartDateChange = (date) => {
    this.setState({
      school_info: {
        ...this.state.school_info,
        cmf_start_date: moment(date).format("YYYY-MM-DD"),
      },
    });
  };
  handleCM_fluencyEndDateChange = (date) => {
    this.setState({
      school_info: {
        ...this.state.school_info,
        cmf_end_date: moment(date).format("YYYY-MM-DD"),
      },
    });
  };
  handleSchoolAddress = (type, evt) => {
    let address = this.state.school_info.address;
    address[type] = evt.target.value;
    this.setState({
      school_info: { ...this.state.school_info, address: address },
    });
  };

  showAddressView = (value) => {
    if (!value) {
      delete this.state.school_info.address;
      this.setState({
        school_info: this.state.school_info,
      });
    } else {
      this.setState({
        school_info: {
          ...this.state.school_info,
          address: {
            country: null,
            state: null,
            pincode: null,
            street_address: null,
            city: null,
          },
        },
      });
    }
    this.setState({ showAdress: value });
  };

  handleCheckboxChange = (event) => {
    this.setState({
      school_info: {
        ...this.state.school_info,
        is_eligible_for_test: event.target.checked,
      },
    });
  };

  render() {
    let state = this.state;
    const { user } = this.props.onboard;

    return (
      <div style={{ overflow: "auto" }}>
        {console.log("state.school_info===", state)}
        <CreateActivityContainer>
          <ActivityMainFormContainer>
            {user.read_only == false ?
              (this.props.inviteCode.schoolEdit ? (
                <h4>Add School</h4>
              ) : (
                <h4>Edit School</h4>
              ))
              : null
            }

            <form>
              <SchoolInputField
                type="text"
                placeholder="Add school name here"
                border={
                  this.props.inviteCode.schoolEdit
                    ? "1px solid #a349c9"
                    : "none"
                }
                radius={this.props.inviteCode.schoolEdit ? "10px" : "0px"}
                fontSize={this.props.inviteCode.schoolEdit ? "14px" : "18px"}
                margin={this.props.inviteCode.schoolEdit ? "3px" : "0px"}
                value={state.school_info.name}
                disabled={
                  this.props.inviteCode.schoolEdit
                    ? !this.props.inviteCode.schoolEdit
                    : true
                }
                onChange={(evt) => this.handleSchoolInfo("name", evt)}
              />
              <SchoolInputField
                type="text"
                placeholder="Enter email"
                border={
                  this.props.inviteCode.schoolEdit
                    ? "1px solid rgba(0,0,0,0.3)"
                    : "none"
                }
                radius={this.props.inviteCode.schoolEdit ? "10px" : "0px"}
                fontSize={this.props.inviteCode.schoolEdit ? "14px" : "18px"}
                margin={this.props.inviteCode.schoolEdit ? "3px" : "0px"}
                value={state.school_info.email}
                disabled={
                  this.props.inviteCode.schoolEdit
                    ? !this.props.inviteCode.schoolEdit
                    : true
                }
                onChange={(evt) => this.handleSchoolInfo("email", evt)}
              />
              <SchoolInputField
                type="text"
                placeholder="Enter contact number"
                border={
                  this.props.inviteCode.schoolEdit
                    ? "1px solid rgba(0,0,0,0.3)"
                    : "none"
                }
                radius={this.props.inviteCode.schoolEdit ? "10px" : "0px"}
                fontSize={this.props.inviteCode.schoolEdit ? "14px" : "18px"}
                margin={this.props.inviteCode.schoolEdit ? "3px" : "0px"}
                value={state.school_info.phone}
                disabled={
                  this.props.inviteCode.schoolEdit
                    ? !this.props.inviteCode.schoolEdit
                    : true
                }
                onChange={(evt) => this.handleSchoolInfo("phone", evt)}
              />
              <h6>School Tag</h6>
              <Select
                className="dropdown-container"
                value={this.state.schoolTagSelected}
                onChange={this.changeSchoolTag}
                options={schoolTagList}
                isDisabled={
                  this.props.inviteCode.schoolEdit
                    ? !this.props.inviteCode.schoolEdit
                    : true
                }
              />
              <div style={{ marginTop: 5 }}>
                <h6>Expiry Date</h6>
                <DateSelector
                  style={{ overflow: "hidden", border: "1px solid #a349c9" }}
                >
                  <DateSelectorIcon className="fa fa-calendar" />
                  <DatePicker
                    selected={this.state.school_info.expiry_date}
                    selectsStart
                    startDate={this.state.school_info.expiry_date}
                    onChange={this.handleExpiryDateChange}
                    minDate={moment().toDate()}
                    placeholderText="Select Expiry date"
                    disabled={
                      this.props.inviteCode.schoolEdit
                        ? !this.props.inviteCode.schoolEdit
                        : true
                    }
                    ref={(el) => this.onDatepickerRef(el)}
                  />
                </DateSelector>
              </div>
              <SchoolInputField
                type="number"
                placeholder="License count"
                border={
                  this.props.inviteCode.schoolEdit
                    ? "1px solid #a349c9"
                    : "none"
                }
                radius={this.props.inviteCode.schoolEdit ? "10px" : "0px"}
                fontSize={this.props.inviteCode.schoolEdit ? "14px" : "18px"}
                margin={this.props.inviteCode.schoolEdit ? "3px" : "0px"}
                value={state.school_info.license_count}
                disabled={
                  this.props.inviteCode.schoolEdit
                    ? !this.props.inviteCode.schoolEdit
                    : true
                }
                onChange={(evt) => this.handleSchoolInfo("license_count", evt)}
              />
              <div>
                {/* <CaseStudyDescription border={this.props.inviteCode.schoolEdit?'1px solid rgba(0,0,0,0.3)':'none'}
                                                  radius={this.props.inviteCode.schoolEdit?'10px':'0px'}
                                                  height={this.props.inviteCode.schoolEdit?'100px':'80px'}
                                                  fontSize={this.props.inviteCode.schoolEdit?'12px':'14px'}
                                                  placeholder="Add description"
                                                  value={state.school_info.description}
                                                  disabled={this.props.inviteCode.active_case_study?!this.props.caseStudy.schoolEdit:false}
                                                  onChange={this.handleDescriptionChange}/> */}
              </div>
              <br></br>
              <h6>Academic Date (From - To)</h6>
              <div style={{ display: "flex" }}>
                <DateSelector
                  style={{ overflow: "hidden", border: "1px solid #a349c9" }}
                >
                  <DateSelectorIcon className="fa fa-calendar" />
                  <DatePicker
                    selected={this.state.school_info.academic.start_date}
                    selectsStart
                    startDate={this.state.school_info.academic.start_date}
                    onChange={this.handleAcedmicStartDateChange}
                    minDate={moment().toDate()}
                    placeholderText="Select start date"
                    disabled={
                      this.props.inviteCode.schoolEdit
                        ? !this.props.inviteCode.schoolEdit
                        : true
                    }
                    ref={(el) => this.onDatepickerRef(el)}
                  />
                </DateSelector>{" "}
                &nbsp;
                <DateSelector
                  style={{ overflow: "hidden", border: "1px solid #a349c9" }}
                >
                  <DateSelectorIcon className="fa fa-calendar" />
                  <DatePicker
                    selected={this.state.school_info.academic.end_date}
                    selectsStart
                    startDate={this.state.school_info.academic.end_date}
                    onChange={this.handleAcedmicEndDateChange}
                    minDate={moment().toDate()}
                    placeholderText="Select end date"
                    disabled={
                      this.props.inviteCode.schoolEdit
                        ? !this.props.inviteCode.schoolEdit
                        : true
                    }
                    ref={(el) => this.onDatepickerRef(el)}
                  />
                </DateSelector>
              </div>
              <br></br>
              <div className="flex">
                <h6>Address</h6>
                <AddButtonContainer>
                  <button
                    type="button"
                    onClick={() => this.showAddressView(!this.state.showAdress)}
                  >
                    {this.state.showAdress ? "-" : "+"}
                  </button>
                </AddButtonContainer>
              </div>
              {this.state.showAdress ? (
                <div>
                  <SchoolInputField
                    type="text"
                    placeholder="Street address"
                    border={
                      this.props.inviteCode.schoolEdit
                        ? "1px solid #a349c9"
                        : "none"
                    }
                    radius={this.props.inviteCode.schoolEdit ? "10px" : "0px"}
                    fontSize={
                      this.props.inviteCode.schoolEdit ? "14px" : "18px"
                    }
                    margin={this.props.inviteCode.schoolEdit ? "3px" : "0px"}
                    value={state.school_info.address.street_address}
                    disabled={
                      this.props.inviteCode.schoolEdit
                        ? !this.props.inviteCode.schoolEdit
                        : true
                    }
                    onChange={(evt) =>
                      this.handleSchoolAddress("street_address", evt)
                    }
                  />
                  <SchoolInputField
                    type="text"
                    placeholder="City"
                    border={
                      this.props.inviteCode.schoolEdit
                        ? "1px solid #a349c9"
                        : "none"
                    }
                    radius={this.props.inviteCode.schoolEdit ? "10px" : "0px"}
                    fontSize={
                      this.props.inviteCode.schoolEdit ? "14px" : "18px"
                    }
                    margin={this.props.inviteCode.schoolEdit ? "3px" : "0px"}
                    value={state.school_info.address.city}
                    disabled={
                      this.props.inviteCode.schoolEdit
                        ? !this.props.inviteCode.schoolEdit
                        : true
                    }
                    onChange={(evt) => this.handleSchoolAddress("city", evt)}
                  />
                  <SchoolInputField
                    type="text"
                    placeholder="State"
                    border={
                      this.props.inviteCode.schoolEdit
                        ? "1px solid #a349c9"
                        : "none"
                    }
                    radius={this.props.inviteCode.schoolEdit ? "10px" : "0px"}
                    fontSize={
                      this.props.inviteCode.schoolEdit ? "14px" : "18px"
                    }
                    margin={this.props.inviteCode.schoolEdit ? "3px" : "0px"}
                    value={state.school_info.address.state}
                    disabled={
                      this.props.inviteCode.schoolEdit
                        ? !this.props.inviteCode.schoolEdit
                        : true
                    }
                    onChange={(evt) => this.handleSchoolAddress("state", evt)}
                  />
                  <SchoolInputField
                    type="number"
                    placeholder="Pincode"
                    border={
                      this.props.inviteCode.schoolEdit
                        ? "1px solid #a349c9"
                        : "none"
                    }
                    radius={this.props.inviteCode.schoolEdit ? "10px" : "0px"}
                    fontSize={
                      this.props.inviteCode.schoolEdit ? "14px" : "18px"
                    }
                    margin={this.props.inviteCode.schoolEdit ? "3px" : "0px"}
                    value={state.school_info.address.pincode}
                    disabled={
                      this.props.inviteCode.schoolEdit
                        ? !this.props.inviteCode.schoolEdit
                        : true
                    }
                    onChange={(evt) => this.handleSchoolAddress("pincode", evt)}
                  />
                  <SchoolInputField
                    type="text"
                    placeholder="Country"
                    border={
                      this.props.inviteCode.schoolEdit
                        ? "1px solid #a349c9"
                        : "none"
                    }
                    radius={this.props.inviteCode.schoolEdit ? "10px" : "0px"}
                    fontSize={
                      this.props.inviteCode.schoolEdit ? "14px" : "18px"
                    }
                    margin={this.props.inviteCode.schoolEdit ? "3px" : "0px"}
                    value={state.school_info.address.country}
                    disabled={
                      this.props.inviteCode.schoolEdit
                        ? !this.props.inviteCode.schoolEdit
                        : true
                    }
                    onChange={(evt) => this.handleSchoolAddress("country", evt)}
                  />
                </div>
              ) : null}
              <CheckBoxContainer>
                <label>
                  <CheckBox
                    checked={state.school_info.is_eligible_for_test}
                    onChange={this.handleCheckboxChange}
                    disabled={
                      this.props.inviteCode.schoolEdit
                        ? !this.props.inviteCode.schoolEdit
                        : true
                    }
                  />
                  <span style={{ marginLeft: 8 }}>Eligible For CM Fluency</span>
                </label>
              </CheckBoxContainer>
              {state.school_info.is_eligible_for_test && (
                <div>
                  <h6>CM Fluency Date (From - To)</h6>
                  <div style={{ display: "flex" }}>
                    <DateSelector
                      style={{
                        overflow: "hidden",
                        border: "1px solid #a349c9",
                      }}
                    >
                      <DateSelectorIcon className="fa fa-calendar" />
                      <DatePicker
                        selected={this.state.school_info.cmf_start_date}
                        selectsStart
                        startDate={this.state.school_info.cmf_start_date}
                        onChange={this.handleCM_fluencyStartDateChange}
                        minDate={moment().toDate()}
                        placeholderText="Select start date"
                        disabled={
                          this.props.inviteCode.schoolEdit
                            ? !this.props.inviteCode.schoolEdit
                            : true
                        }
                        ref={(el) => this.onDatepickerRef(el)}
                      />
                    </DateSelector>{" "}
                    &nbsp;
                    <DateSelector
                      style={{
                        overflow: "hidden",
                        border: "1px solid #a349c9",
                      }}
                    >
                      <DateSelectorIcon className="fa fa-calendar" />
                      <DatePicker
                        selected={this.state.school_info.cmf_end_date}
                        selectsStart
                        startDate={this.state.school_info.cmf_end_date}
                        onChange={this.handleCM_fluencyEndDateChange}
                        minDate={moment().toDate()}
                        placeholderText="Select end date"
                        disabled={
                          this.props.inviteCode.schoolEdit
                            ? !this.props.inviteCode.schoolEdit
                            : true
                        }
                        ref={(el) => this.onDatepickerRef(el)}
                      />
                    </DateSelector>
                  </div>
                  <br />
                  <br />
                </div>
              )}
              <SchoolGuidedPathEnable />
              <ActivityContinueButtonContainer>
                {this.props.inviteCode.schoolEditInfo &&
                this.props.inviteCode.schoolEditInfo.id ? (
                  this.props.inviteCode.schoolEdit ? (
                    <ThemeButton
                      primary
                      type="button"
                      onClick={this.handleUpdateBtn}
                    >
                      {" "}
                      Update School{" "}
                    </ThemeButton>
                  ) : (
                    <ThemeButton
                      primary
                      type="button"
                      onClick={this.handleEditBtn}
                    >
                      {" "}
                      Edit School{" "}
                    </ThemeButton>
                  )
                ) : (
                  <ThemeButton
                    primary
                    type="button"
                    onClick={this.handleContinueBtn}
                  >
                    {" "}
                    Create School{" "}
                  </ThemeButton>
                )}
              </ActivityContinueButtonContainer>
            </form>
            <br></br>
            <br></br>
          </ActivityMainFormContainer>
        </CreateActivityContainer>
      </div>
    );
  }
}

export default connectStore({
  ui,
  inviteCode,
  product,
  onboard,
})(SchoolCreatorForm);
// export default  CaseStudyCreatorForm;
