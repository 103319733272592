import React, { useState } from 'react'

const ReportingView = () => {

  const [loading,setLoading] = useState(true)

  const handleIframeLoad = ()=>{
      setLoading(false)
  }

  return (
    <div style={{ width: "100%", height: "100%", marginLeft: "1rem" }}>
      {loading && (
        <div style={{width:'100%',height:'85vh', zIndex: 100, display:'flex',alignItems:'center',justifyContent:'center',background:'#fff' }}>
          <img
            alt="loading"
            width="60"
            height="60"
            src={require("static/img/Theme-loader.gif")}
          />
        </div>
      )}
      <iframe
        src="https://lookerstudio.google.com/embed/reporting/c2916f1a-47ff-4d5a-978d-b28480ef6cc4/page/QNOYD"
        frameborder="0"
        style={{ border: 0, width: "100%", height: "85vh" }}
        allowfullscreen
        onLoad={handleIframeLoad}
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      ></iframe>
    </div>
  );
}

export default ReportingView