import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import { module as story } from "@st/story";
import { connectStore } from "redux-box";
import swal from "sweetalert2";
import {
  NavigationHeaderContainer,
  NavigationContainer
} from "@sc/NavigationBar.styled";
import StoryCard from "./story-card/StoryCard";
import Table from "@c/common/table";
import {
  EmptyContainer,
  InfiniteScrollParentContainer,
  LoadingContainer,
  AlignContentHorizontal,
  AutoContainer,
  InfiniteScrollLoader
} from "@sc/custom.styled";
import DatePicker from "react-datepicker";
import { DateSelectionField } from "@sc/custom.styled";
import moment from "moment";
import { module as onboard } from "@st/onboard";
import Permission from "@s/permission";

const storyColData=[
  {
    name: "Name",
    value: "name",
    type: "TEXT"
  },
  {
    name: "Grade Levels",
    value: "grade_level",
    type: "LIST"
  },
  {
    name: "Collections",
    value: "collection",
    key:"name",
    type: "LIST"
  },
  {
    name: "Source",
    value: "source",
    type: "TEXT"
  },
  {
    name: "Author Name",
    value: "author_name",
    type: "TEXT"
  },
  {
    name: "book",
    value: "reading_count",
    type: "ICONS"
  },
  {
    name: "heart",
    value: "like_count",
    type: "ICONS"
  },
  {
    name: "eye",
    value: "view_count",
    type: "ICONS"
  },
  {
    name: "Is Top Story",
    value: "top_story",
    type: "TEXT"
  },
  {
    name: "Story Type",
    value: "story_type",
    type: "TEXT"
  },
  {
    name: "MCQ",
    value: "has_mcq",
    type: "TEXT"
  },
  {
    name: "Created Date",
    value: "created_at",
    type: "DATE"
  },
]


 class StoryViewList extends Component {
  constructor(props) {
    super();
    this.state = {
      // date: moment(),
      levelSelected: "Easy",
      currentDifficulty: '',
      // date: new Date(),
      date: '',
      displayType: 'card'
    };
  }
  componentDidMount = () => {
    if (this.props.story.story_date) {
      let newDate = moment.unix(this.props.story.story_date).format('YYYY-MM-DD');
      this.setState({
        date: newDate
      })
    }
  }
  // componentWillUnmount = () => {
  //   let { story } = this.props;
  //   story.requestStories(0);
  // }

  fetchMoreStories = () => {
    if (
      this.props.story.stories_result !== null &&
      this.props.story.stories_result.has_next
    ) {

      setTimeout(() => {
        if (
          this.props.story.stories_result !== null &&
          this.props.story.stories_result.next_page > 0
        )
          this.props.story.requestStoriesListConcat(
            this.props.story.stories_result.next_page
          );
      }, 300);
    }
  };
  onDatepickerRef(el) {
    if (el && el.input) {
      el.input.readOnly = true;
    }
  }
  handleLevelSelector = data => {
    this.setState({
      currentDifficulty: data,
    })
    // fix search when toggle between easy and hard
    // this.props.story.setSearchStory("");
    this.props.story.setActiveStoryLevel(data);
    this.props.story.requestStories();
    this.setState({
      levelSelected: data
    });
  };
  //function for date selector (function starts)
  handleDateChange = date => {
    let { story } = this.props;
    this.setState({
      date: date
    })
    if (date) {

      let selectedDate = new Date(date);
      // console.log(selectedDate)
      // let selectedDate = new Date(date.d);
      let month =
        selectedDate.getMonth() >= 9
          ? selectedDate.getMonth() + 1
          : "0" + (selectedDate.getMonth() + 1);
      let day =
        selectedDate.getDate() < 10
          ? "0" + selectedDate.getDate()
          : selectedDate.getDate();
      let dateData = selectedDate.getFullYear() + "-" + month + "-" + day;

      story.setActiveStoriesDate(new Date(dateData).getTime() / 1000);
      story.requestStories(0);
      // console.log(dateData);
    }
  };
  //function for date selector in stories(function ends)
  handleClearBtn = () => {
    let { story } = this.props;
    this.setState({
      date: ""
    });
    story.setActiveStoriesDate("");
    story.requestStories(0);
  };

  handleDeleteClick = (story, index, e) => {
    e.preventDefault();
    swal({
      text: "Are you sure that you want to delete this story?",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      story_list: [],

    }).then((result) => {
      if (result.value) {
        this.props.story.requestDeleteStory({
          storyId: story.id,
          storyIndex: index
        });
      }
    });
  }

  render() {
    const { user } = this.props.onboard;
    const data = ["Easy", "Hard"];
    let { story } = this.props;
    let levelList = data.map((level, i) => (
      <Link
        to={"/stories/" + story.active_story_grade_level + "/" + level}
        key={i}
        className={
          level === this.props.story.active_story_level ? "active" : ""
        }
        onClick={() => this.handleLevelSelector(level)}
      >
        {level}
        {this.props.story.active_story_level === level && (
          <span style={{ paddingLeft: "8px" }}>
            (
            {story.stories_result
              ? this.props.story.fetch_story_list
                ? "...."
                : story.stories_result.count
              : "...."}
            )
          </span>
        )}
      </Link>
    ));
    let storyList = (
      <AlignContentHorizontal marginBottom="70px">
        {story.story_list.map((data, index) => (
          <div className="col-sm-6 col-md-4 col-lg-3" key={index}>
            { 
              <Link
                to={
                  "/stories/" +
                  story.active_story_grade_level +
                  "/" +
                  story.active_story_level +
                  "/edit/" +
                  data.id +
                  "/" +
                  index
                }
              >
                <StoryCard storyDetail={data} handleDeleteClick={(e) => this.handleDeleteClick(data, index, e)} index={index} />
              </Link>
            
            }

          </div>
        ))}
      </AlignContentHorizontal>
    );
    return (
      <AutoContainer>
        <NavigationHeaderContainer shadow>
          <NavigationContainer>{levelList}</NavigationContainer>

          <div id="payments-date" style={{display: 'flex'}} >
            <DateSelectionField width="220px" style={{ overflow: "hidden" }}>
              <img src={require("static/img/date-icon.png")} alt="startDate" />
              <DatePicker
                selected={this.state.date}
                selectsStart
                startDate={this.state.date}
                onChange={this.handleDateChange}
                placeholderText='Select date'
                ref={el => this.onDatepickerRef(el)}
              />
              {this.state.date ? (
                <i
                  className="fa fa-times"
                  onClick={() => this.handleClearBtn()}
                  style={{ padding: "8px", cursor: "pointer" }}
                />
              ) : null}
            </DateSelectionField>

            {(this.state.displayType=== 'card')?
              (<button type="button" class="btn btn-warning text-white" 
                data-toggle="tooltip" data-placement="top" 
                title="Show in Table Format"
                style={{background: '#ea882b'}}
                onClick={()=>this.setState({displayType:"table"})}>
                <i className="fa fa-table" />
              </button>):
              (<button type="button" class="btn btn-warning text-white" 
                data-toggle="tooltip" data-placement="top" 
                title="Show in Cards Format"
                style={{background: '#ea882b'}}
                onClick={()=>this.setState({displayType:"card"})}>
                <i className="fa fa-id-card-o" />
              </button>)
            }
            
          </div>
          
        </NavigationHeaderContainer>
        {this.props.story.fetch_story_list ? (
          <LoadingContainer style={{ zIndex: "100" }}>
            <img
              alt="loading"
              width="60"
              height="60"
              src={require("static/img/Theme-loader.gif")}
            />
          </LoadingContainer>
        ) : this.props.story.story_list &&
          this.props.story.story_list.length > 0 ? (
              <InfiniteScrollParentContainer>
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.fetchMoreStories}
                  hasMore={
                    this.props.story.stories_result &&
                    this.props.story.stories_result.has_next
                  }
                  style={{ position: "relative" }}
                  loader={
                    <InfiniteScrollLoader className="loader" key={0}>
                      <img
                        alt="loading"
                        width="50"
                        height="50"
                        src={require("static/img/Theme-loader.gif")}
                      />
                    </InfiniteScrollLoader>
                  }
                  useWindow={false}
                >
                  {(this.state.displayType=== 'card')?
                    (storyList):
                    (<Table colData={storyColData} tableData={this.props.story.story_list} />)
                  }
                  
                </InfiniteScroll>
              </InfiniteScrollParentContainer>
            ) : (
              <EmptyContainer>
                <h4> NO STORIES ADDED FOR THIS LEVEL</h4>
              </EmptyContainer>
            )}
      </AutoContainer>
    );
  }
}
export default connectStore({
  story,onboard
})(StoryViewList)