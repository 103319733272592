import React, { useEffect } from "react";
import { connectStore } from "redux-box";
import { module as ui } from "@st/ui";
import { module as product } from "@st/product";
import ProductCard from "./product-card";
import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";

import {
  LoadingContainer,
  MainBodyContentContainer,
  AlignContentHorizontal,
  PlusButton,
  EmptyContainer,
  PlusIcon,
} from "@sc/custom.styled";

function ProductView(props) {
  const { user } = props.onboard;
  const user_group = user.groups[0].name;

  useEffect(() => {
    props.product.fetchProductList();
  }, []);

  const handleProductSelect = (selectedProduct) => {
    let path = `school-guided-path?id=${selectedProduct.id}`;

    props.product.setSelectedProduct(selectedProduct);
    return props.history.push(path);
  };

  const handleEditClick = (e, product) => {
    var el = e;
    props.product.setEditProduct(product);
    props.ui.showModal("PRODUCTUPDATEFORM");
    if (!el) {
      el = window.event;
    }
    el.cancelBubble = true;
    if (el.stopPropagation) el.stopPropagation();
  };

  const handleAddClickButton = () => {
    if(user.read_only == true) return;
    props.product.setEditProduct({
      thumbnail_url: false,
      description: "",
      name: "",
    });
    props.ui.showModal("productCreateForm");
  };

  return (

    <MainBodyContentContainer>
      <MainBodyContentContainer>
        {props.product.isProductLoading && (
          <LoadingContainer style={{ zIndex: "100" }}>
            <img
              alt="loading"
              width="60"
              height="60"
              src={require("static/img/Theme-loader.gif")}
            />
          </LoadingContainer>
        )}
        {!props.product.isProductLoading && (
          <AlignContentHorizontal marginBottom="70px">
            {props.product.product_list &&
            props.product.product_list.length > 0 ? (
              props.product.product_list.map((product) => {
                return (
                  <div className="col-sm-6 col-md-4 col-lg-3" key={product.id}>
                    <ProductCard
                      handleProductSelect={() => handleProductSelect(product)}
                      {...{ product }}
                      handleEditClick={(event) =>
                        handleEditClick(event, product)
                      }
                    />
                  </div>
                );
              })
            ) : (
              <EmptyContainer>
                <h4>NO PRODUCTS AVAILABLE</h4>
              </EmptyContainer>
            )}
          </AlignContentHorizontal>
        )}
      </MainBodyContentContainer>
      {!user.read_only && user_group != 'publisher' ?
        <PlusButton
          hide={props.product.isAddProductFormOpened}
          onClick={handleAddClickButton}
        >
          <PlusIcon
            primary
            alt="Plus Icon"
            cross={false}
            src={require("static/img/plusicon.png")}
          />
        </PlusButton>
      :null}
    </MainBodyContentContainer>
  );
}
export default connectStore({
  ui,
  product,
  onboard,
})(ProductView);
