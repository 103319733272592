import React, {Component} from 'react';
import {
    CardContainer,
    Title,
    FooterText,
    StyledDiv,
    StyledDivImage
} from "./FreadomFutureCard.styled";
import { connectStore } from "redux-box";
import {CardIcon, CardIconContainer, CountOverlayContainer, CountItem} from "static/styled/custom.styled";
import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";

class FreadomFutureCard extends Component
{
    render(){
        const freadomFutureInfo = this.props.freadomFutureInfo;
        const { permission } = this.props.onboard;
        const { user } = this.props.onboard;

        return (
            <CardContainer>
                <CardIconContainer>
                    {/* {Permission.getPermission(permission, "change_reading") ?
                        <CardIcon onClick={this.props.handlePublishClick} primary bg={freadomFutureInfo.is_published}>
                            <i className="fa fa-check" />
                        </CardIcon> : null} */}

                    {user.read_only == false && Permission.getPermission(permission, "delete_reading") ?
                        <CardIcon>
                            <i className="fa fa-trash" />
                        </CardIcon> : null}

                </CardIconContainer>
                <Title>{freadomFutureInfo.name}</Title>
            </CardContainer>
        )
    }
}
export default connectStore({
    onboard
})(FreadomFutureCard)
