import React, { Component } from "react";
import { connectStore } from "redux-box";
import { module as quiz2 } from "@st/quiz2";

import {
  CardContainer,
  StyledDiv,
  StyledDivImage,
  Title,
} from "@c/activity-view/activities-list/ActivityCard/ActivityCard.styled";
import {
  CardIcon,
  CardIconContainer,
} from "static/styled/custom.styled";
import Permission from "@s/permission";
import { module as onboard } from "@st/onboard";

class QuizCard extends Component {
  render() {
    const quizInfo = this.props.quizInfo;
    const { permission } = this.props.onboard;
    const {user} = this.props.onboard;

    return (
      <CardContainer>
        <StyledDiv secondary>
          <Title>{quizInfo.name}</Title>
          <Title primary>
            {this.props.quizInfo &&
            this.props.quizInfo.description &&
            this.props.quizInfo.description.length < 36
              ? quizInfo.description
              : quizInfo.description.slice(0, 40) + "..."}
          </Title>
        </StyledDiv>
        <StyledDivImage primary image={quizInfo.image}>
          <CardIconContainer>
            {user.read_only == false && Permission.getPermission(permission, "delete_quiz") ? (
              <CardIcon onClick={this.props.handleDeleteClick}>
                <i className="fa fa-trash" />
              </CardIcon>
            ) : null}
          </CardIconContainer>
        </StyledDivImage>
      </CardContainer>
    );
  }
}
export default connectStore({ quiz2, onboard })(QuizCard);
