import React, { Component } from "react";
import { module as ui } from "@st/ui";
import { module as activity } from "@st/activity";
import { module as onboard } from "@st/onboard";
import ActivityOverlayRouter from "@r/activityOverlayRouter";
import ActivityTabRouter from "@r/activityTabRouter";
import { connectStore } from "redux-box";
import ActivityViewHeader from "@c/activity-view/activity-view-header/ActivityViewHeader";
import ActivityPackLevelHeader from "./activity-pack-level-header/ActivityPackLevelHeader";
import {
  MainBodyContainer,
  MainBodyContentContainer,
  PlusButton,
  PlusIcon,
} from "@sc/custom.styled";
import Permission from "@s/permission";
import swal from "sweetalert2";
// export default connectStore({
//   ui,
//   activity,
// })
class ActivityView extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() {
    this.props.activity.requestGrades();
  }
  handleAddClickButton = (e) => {
    const {permission} =this.props.onboard;
    const {user} = this.props.onboard;

    let loc = this.props.location;
    let gradeLevelPath =
      this.props.activity.active_grade_level +
      "/" +
      this.props.activity.active_level;

    if (loc.pathname.includes("/activities/edit")) {
      this.props.history.push("/activities");
    } else if (
      this.props.activity.active_activity &&
      this.props.activity.active_activity_pack === null
    ) {
      if (
        loc.pathname.includes(
          "/activities/packs/" +
            gradeLevelPath +
            "/" +
            this.props.activity.active_activity.activity_pack +
            "/activity"
        )
      )
        this.props.history.push(
          "/activities/packs/" +
            gradeLevelPath +
            "/edit/" +
            this.props.activity.active_activity.activity_pack
        );
    } else if (
      loc.pathname.includes("/activities/packs/") &&
      this.props.activity.active_activity_pack &&
      this.props.activity.active_activity
    ) {
      this.props.history.push(
        "/activities/packs/" +
          gradeLevelPath +
          "/edit/" +
          this.props.activity.active_activity.activity_pack
      );
    } else if (
      loc.pathname ===
      "/activities/packs/" +
        this.props.activity.active_grade_level +
        "/" +
        this.props.activity.active_level
    ) {
      this.props.ui.showModal("ACTIVITYPACKFORM");
    } else if (loc.pathname.includes("/activities/packs/Level")) {
      this.props.history.push(
        "/activities/packs/" +
          this.props.activity.active_grade_level +
          "/" +
          this.props.activity.active_level
      );
    } else if (loc.pathname === "/activities") {
      if (user.read_only === false ? Permission.getPermission(permission, "add_activity") : null) {
        this.props.ui.showModal("ACTIVITYFORM");
      } else {
        swal({
          text: "You do not have permission.",
          type: "warning"
        });
      }      
      
    } else if (
      loc.pathname ===
      "/activities/packs/" +
        this.props.activity.active_grade_level +
        "/" +
        this.props.activity.active_level
    ) {
      this.props.ui.showModal("ACTIVITYPACKFORM");
    }
  };

  render() {
    
    // console.log("user:",user);
    const isCreatorOpen =
      this.props.location.pathname === "/activities/activity-detail" ||
      this.props.location.pathname.includes("/activities/edit") ||
      (this.props.location.pathname !== "/activities" &&
        this.props.location.pathname !==
          "/activities/packs/" +
            this.props.activity.active_grade_level +
            "/" +
            this.props.activity.active_level);

    return (
      <MainBodyContainer>
        <ActivityOverlayRouter />
        <ActivityViewHeader />
        {this.props.location.pathname.includes("/activities/packs") ? (
          <ActivityPackLevelHeader />
        ) : null}
        <MainBodyContentContainer
          style={
            this.props.location.pathname.includes("/activities/packs")
              ? { minHeight: "calc(100vh - 240px)" }
              : {}
          }
        >
          <ActivityTabRouter />
        </MainBodyContentContainer>
        {/* {Permission.getPermission(permission, "add_activity")?  */}
        
          <PlusButton
            hide={this.props.ui.show_modal}
            onClick={this.handleAddClickButton}
          >
            <PlusIcon
              primary
              alt="Plus Icon"
              cross={
                isCreatorOpen &&
                !this.props.location.pathname.endsWith("/activity/new")
              }
              src={require("static/img/plusicon.png")}
            />
          </PlusButton>
        
        {/* :null } */}
      </MainBodyContainer>
    );
  }
}
// export default ActivityView;
export default connectStore({
  ui,
  activity,
  onboard
 })(ActivityView)